//import { upload } from "@testing-library/user-event/dist/upload";
import React, { useState, useRef, useEffect } from "react";
import "./UploadForm.scss";
import UploadFormStep1 from "./UploadFormStep1";
import UploadFormStep2 from "./UploadFormStep2";
import UploadFormStep3 from "./UploadFormStep3";
import UploadFormStep4 from "./UploadFromStep4";
import UploadFormReview from "./UploadFormReview";
import ArtistsAndContributors from "../ArtistsAndContributors/ArtistsAndContributors";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoader } from "../LoaderProvider/LoaderProvider";

import { ToastContainer, toast } from 'react-toastify';
import {API_ROUTE} from "../../utilities/api";

const UploadForm = ({ editProduct, subGenres, labels, addProductToList }) => {


    const loader = useLoader();

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const [formState, setFormState] = useState(1);
    //const [headerText, setHeaderText] = useState("General");
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        "state": "incomplete",
        "release_format_type": "",//"release_format_type": "",
        "name": "",//"name": "",
        "release_version": "",//?
        "label": { name: "", id: 0, fuga_label_id: "", user_id: "", is_sub_label: false },//"label": "",
        "tracks": [],
        "genre": "",//"genre": {name: ""}
        "subgenre": "",//"subgenre": {name: ""}
        "parental_advisory": false,
        "recording_location": "",
        "language": "",
        "audio_language": "",
        "original_release_date": "",
        "consumer_release_date": "",
        "preorder_date": "",
        "upc": "",
        "isrc": "",
        "c_line_text": "",
        "c_line_year": "",
        "p_line_text": "",
        "p_line_year": "",
        "clip_start_time": "",
        "catalog_number": "",

        "artists": [{
            "name": "", "primary": true
        }],
        "writers": [],
        "contributors": [],

        "coverImg": {},
        "cover_image_string": ""
    })

    const checkForMainArtist = () => {
        let bool = false;
        formData.artists.forEach(artist => {
            if (artist.primary == true && artist.name !== "") {
                console.log("role:", artist.primary)
                console.log("name", artist.name)
                bool = true;
            }
        });
        return bool;
    }

    const next = () => {
        console.log("next")
        if (formState === 1) {
            if (formData.release_format_type !== "" && formData.name !== "" && formData.label !== "" && formData.track !== {}) {
                setFormState(2);
                setError(false);
            } else { setError(true) }
        } else if (formState === 2) {
            if (formData.p_line_year !== "" && formData.p_line_text !== "" && formData.c_line_year !== "" && formData.c_line_text !== "" && formData.genre !== "" && formData.parental_advisory !== "" && formData.language !== "" && formData.audio_language !== "" && formData.preorder_date !== "" && formData.consumer_release_date !== "" && formData.original_release_date !== "") {
                setFormState(3);
                setError(false);
            } else { setError(true) }
        } else if (formState === 3) {
            if (checkForMainArtist() === true) {
                //console.log(checkForMainArtist(formData.Artists));
                setFormState(4);
                console.log(formState);
                setError(false);
            } else { setError(true) }
        } else if (formState === 4) {
            // if(formData.CoverImg !== {}){
            setFormState(5);
            //    setError(false);
            // } else{setError(true)}
        }
    }
    const back = () => {
        if (formState >= 2) {
            setFormState(formState - 1);
            setError(false);
        }
        console.log("state:", formState);
    }

    const handlePage = () => {
        switch (formState) {
            case 1: return (
                <UploadFormStep1 handleChange={handleChange} data={formData} labels={labels} />
            )
            case 2: return (
                <UploadFormStep2 handleChange={handleChange} data={formData} subGenres={subGenres} />
            )
            case 3: return (
                <ArtistsAndContributors
                    isTrack={false}
                    handleChange={handleChange}
                    data={formData}
                    showArtistsSection={true}
                    showContributorsSection={false}
                    showPublishersSection={false} />
            )
            case 4: return (
                <UploadFormStep4 handleChange={handleChange} data={formData} />
            )
            case 5: return (
                <UploadFormReview data={formData} />
            )
            default: return (
                <UploadFormStep1 handleChange={handleChange} data={formData} />
            )
            //break;
        }
    }
    const errorText = () => {
        if (!error) {
            return (<span></span>);
        } else if (formState === 3 && error) {
            return (<span>Releases need at least one Main Artist</span>)
        } else {
            return (<span>Please fill out the required<span className="upload-form__span">*</span> fields</span>)
        }
    }
    const hideSubmit = `${(5 === formState) ? "upload-form__submit" : "upload-form__submit--hidden"}`;
    const hideBack = `${(1 === formState) ? "upload-form__bottom__back--hidden" : "upload-form__bottom__back"}`;
    const hideNext = `${(5 === formState) ? "upload-form__bottom__next--hidden" : "upload-form__bottom__next"}`;
    const handleHeader = () => {
        switch (formState) {
            case 1:
                return "General"
            case 2:
                return "Recording Information"
            case 3:
                return "Artists & Contributors"
            case 4:
                return "Upload Artwork"
            case 5:
                return "Review"
            default:
                break;
        }
    }

    const handleChange = async (e) => {
        let newData = {}
        if (e.target.name === 'coverImg') {
            if (e.target.value) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    newData = { ...formData, [e.target.name]: reader.result, cover_image_string: reader.result }
                    setFormData(newData);
                }
                reader.readAsDataURL(e.target.value[0]);
            } else {
                newData = { ...formData, [e.target.name]: null, cover_image_string: '' }
                setFormData(newData);
            }
        } else {
            newData = { ...formData, [e.target.name]: e.target.value }
            setFormData(newData);
        }
    }
    console.log(formData, 'FormDataaa--->>>')

    const dataURLtoFile = (dataurl, filename) => {
        console.log(dataurl, 'dataURL')
        var arr = dataurl?.split(','),
            mime = arr[0]?.match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const handleSubmit = async (event) => {
        //save data to backend
        console.log('handlesubmit ran');
        //load track editor
        //const value = data.get('');
        console.log('formData::', formData)
        const asset_file_Image_URL = formData.cover_image_string;
        console.log('asset file', asset_file_Image_URL)
        // if (!!formData.cover_image_string) {
        //     const split = formData.cover_image_string?.split(',');
        //     formData.cover_image_string = `${split[1]}`;
        // }
        formData.coverImg = null;
        const { cover_image_string, ...rest } = formData;

        console.log('restData', rest)
        let submitFormData = new FormData();
        submitFormData.append("asset_file", asset_file_Image_URL);
        if (rest.genre.name != null) {
            rest.genre = rest.genre.id;
        }
        if (rest.subgenre.id != null){
            rest.subgenre = rest.subgenre.id;
        }
        submitFormData.append("product", JSON.stringify({ rest }));
        const token = await getAccessTokenSilently();

        loader.showLoader();

        await fetch(`${API_ROUTE}/create-product-two/`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept': "multipart/form-data"
            },
            body: submitFormData
            ,
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();

            console.log("products:", data)

            if (data.is_request_successful) {
                toast.success(data.response_message);

                const currentData = {...data.product_result};
                currentData['cover_image_string'] = asset_file_Image_URL?.split(',')[1];
                editProduct(currentData, true);
                addProductToList(currentData);
            } else {
                toast.error(data.response_message)
            }

        }).catch(() => {
            loader.hideLoader(error);
            toast.error(error)
        })
    }

    useEffect(() => {//for testing purposes
        console.log("newFormData:", formData);
    }, [formData]);

    return (
        <>
            <div className="upload-form">
                <div className="upload-form__header">
                    <h2 className="upload-form__header__header">{handleHeader()}</h2>
                    <div className={`upload-form__header__progress${formState}`}><p>1</p><p>2</p><p>3</p><p>4</p></div>
                </div>
                {handlePage()}
                <div className="upload-form__bottom">
                    <button className={hideBack} onClick={() => back()}>Prev</button>
                    <div className="upload-form__error-text">{errorText()}</div>
                    <button className={hideNext} onClick={() => next()}>Next</button>
                    <button className={hideSubmit} onClick={handleSubmit}>Edit Tracks</button>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored" />
            </div>

        </>
    )
}

export default UploadForm;

/*

<label className="upload-form__label">Format<span className="upload-form__span">*</span></label>
            <div className="upload-form__input">
                <select className="upload-form__input__select" name="Format" onChange={handleChange}>
                    <option value="" disabled selected>Select...</option>
                    <option value="Single" >Single</option>
                    <option value="EP" >EP</option>
                    <option value="Album" >Album</option>
                </select>
            </div>
            <label className="upload-form__label">Title<span className="upload-form__span">*</span></label>
            <input type="text" name="Title" className="upload-form__input" onChange={handleChange}></input>
            <label className="upload-form__label">Title Version<span className="upload-form__tooltip">i</span></label>
            <input type="text" name="Title_Version" className="upload-form__input" onChange={handleChange}></input>
            <label className="upload-form__label">Label<span className="upload-form__span">*</span></label>
            <div className="upload-form__input">
                <select className="upload-form__input__select" name="label" onChange={handleChange}>
                    <option value="" disabled selected>Select...</option>
                    {labels && labels.map((e) => {
                        return (
                            <option value={e} >{e}</option>
                        );
                    })}
                </select>
            </div>

            <div className="upload-form__uploader"
            ref={wrapperRef}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}>
                <svg className="upload-form__uploader__drop-zone__svg" aria-hidden="true" role="img" id="footer-sample-full" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M15.213 6.639c-.276 0-.546.025-.809.068C13.748 4.562 11.716 3 9.309 3c-2.939 0-5.32 2.328-5.32 5.199c0 .256.02.508.057.756a3.567 3.567 0 0 0-.429-.027C1.619 8.928 0 10.51 0 12.463S1.619 16 3.617 16H8v-4H5.5L10 7l4.5 5H12v4h3.213C17.856 16 20 13.904 20 11.32c0-2.586-2.144-4.681-4.787-4.681z"></path></svg>
                <div className="upload-form__uploader__drop-zone__text">Drag and Drop files to upload</div>
                <input className="upload-form__uploader__drop-zone__input"

                accept=".wav,.flac"
                multiple type="file"
                autoComplete="off"
                tabIndex="-1"
                onChange={onFileDrop}
                ></input>
            </div>

            */
