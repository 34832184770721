import React from "react";
import "./ProductTape.scss";
import {useAuth0} from "@auth0/auth0-react";
import liteImage from "../../assets/img/cassettes/lite.png";
import plusImage from "../../assets/img/cassettes/plus.png";
import proImage from "../../assets/img/cassettes/pro.png";
import { PRICES_IDS} from "../../utilities/constants";

const IMAGES_SOURCES = {
    [PRICES_IDS.LITE]: liteImage,
    [PRICES_IDS.PLUS]: plusImage,
    [PRICES_IDS.PRO]: proImage,
}

const PATHS = {
    [PRICES_IDS.LITE]: '/register-lite',
    [PRICES_IDS.PLUS]: '/register-plus',
    [PRICES_IDS.PRO]: '/register-pro',
}

const PRICES = {
    [PRICES_IDS.LITE]: 24.99,
    [PRICES_IDS.PLUS]: 65.99,
    [PRICES_IDS.PRO]: 129.99,
}

const SHORT_NAMES = {
    [PRICES_IDS.LITE]: 'LITE',
    [PRICES_IDS.PLUS]: 'PLUS',
    [PRICES_IDS.PRO]: 'PRO',
}

const ProductTape = ({product, priceId}) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const getImageSource = () => {
        return IMAGES_SOURCES[priceId]
    }
    const getShortName = () => {
        return SHORT_NAMES[priceId];
    }
    const getPath = () => {
        return PATHS[priceId];
    }
    const getPrice = () => {
        return PRICES[priceId];
    }
    const addToCart = async () => {
        const path = getPath(priceId)
        if (isAuthenticated) {
            window.location.pathname = path;
        } else {
            await loginWithRedirect({
                screen_hint: "signup",
                appState: { returnTo: path}
            })
        }
    }
    return (
        <li className="product-tape" key={product.id}>
            <div className="product-tape__cassette" onClick={addToCart}>
                <img className="product-tape__image" src={getImageSource()}
                     alt="3toneMusic"></img>
                <div className="product-tape__buy-button">
                    <span>
                        { isAuthenticated ? 'Add to cart' : 'Sign Up to Purchase' }
                    </span>
                </div>
            </div>
            <div className="product-tape__product-info">
                <span className="product-tape__product-name">{ getShortName() }</span>
                <span className="product-tape__product-price">£{ getPrice() }</span>
                <span className="product-tape__product-period">/ year</span>
            </div>
        </li>
    );
}

export default ProductTape;
