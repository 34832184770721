import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0ProviderWithHistory} from "./auth0-provider-with-history";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "redux-zero/react";
import store from "./store";

//import Navbar from './components/Navbar/Navbar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Auth0ProviderWithHistory
                    domain="3tone.eu.auth0.com"
                    clientId="1TREwChSioF7yRgys1yBujrDL9aDbOVJ"
                    audience="https://3tone.eu.auth0.com/api/v2/"
                    redirectUri={window.location.origin}>
                    <App/>
                </Auth0ProviderWithHistory>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
