import React, { useState, useEffect } from 'react';
import "./WhatsIncluded.scss";

const WhatsIncluded = () => {

    const [includes, setIncludes] = useState([
        {
            title: 'Release unlimited tracks',
            lite: true,
            plus: true,
            pro: true
        },
        {
            title: 'Release unlimited artists',
            lite: true,
            plus: true,
            pro: true
        },
        {
            title: 'Keep 100% of your royalties',
            lite: true,
            plus: true,
            pro: true
        },
        {
            title: 'Pre-save & smart links',
            lite: true,
            plus: true,
            pro: true
        },
        {
            title: 'Vevo channel creation & unlimited vevo uploads',
            lite: false,
            plus: true,
            pro: true
        },
        {
            title: 'Vevo music video pitching',
            lite: false,
            plus: true,
            pro: true
        },
        {
            title: 'Team of dedicated representatives',
            lite: false,
            plus: false,
            pro: true
        },
        {
            title: 'Spotify, Apple Music, Amazon & Deezer Editorial Pitching*',
            lite: false,
            plus: false,
            pro: true
        },
        {
            title: 'Official chart registration (UK, USA, Canada, Australia & New Zealand',
            lite: false,
            plus: false,
            pro: true
        },
        {
            title: 'Option for Facebook, Instagram & TikTok verification*',
            lite: false,
            plus: false,
            pro: true
        },
        {
            title: 'Optional performance and mechanical royalty collection*',
            lite: false,
            plus: false,
            pro: true
        },
    ]);

  return (
      <div className="included-container">
          <div className="container">
              <h1 className="included-container__header">What's included</h1>
              <div className="included-container__table">
                  <div className="included-container__row">
                      <div className="included-container__cell">
                      </div>
                      <div className="included-container__cell">
                          Lite
                      </div>
                      <div className="included-container__cell">
                          Plus
                      </div>
                      <div className="included-container__cell">
                          Pro
                      </div>
                  </div>
                  {
                      includes && includes.map((item, index) => (
                          <div className="included-container__row" key={index}>
                              <div className="included-container__cell">
                                  {item.title}
                              </div>
                              <div className="included-container__cell" data-name={'Lite'}>
                                  {item.lite && (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
                                      </svg>
                                  )}
                              </div>
                              <div className="included-container__cell" data-name={'Plus'}>
                                  {item.plus && (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
                                      </svg>
                                  )}
                              </div>
                              <div className="included-container__cell" data-name={'Pro'}>
                                  {item.pro && (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
                                      </svg>
                                  )}
                              </div>
                          </div>
                      ))
                  }
              </div>
              <span className="included-container__notice">*Subject to eligibility criteria and technical specifications. Service is limited to one artist per one user account only. For more information please get in touch with us.</span>
          </div>
      </div>
  )
}

export default WhatsIncluded;
