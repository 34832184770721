import React from "react";
import "./Catalogue.scss"
//import img1 from "../../assets/img/Asset20n.png";
//import img2 from "../../assets/img/Asset21n.png";
//import img5 from "../../assets/img/Asset25n.png";
import { products } from "../../assets/mockData/products";

const Catalogue = (props) => {
    const { products, editProduct, noProducts } = props;
    console.log("products", products);
    console.log("editProduct", editProduct);
    // const {editProduct} = props;

    const setLightClass = (status) => {
        if (status.toLowerCase() === "delivered") {//DELIVERED
            return "music-dashboard__catalogue__section__light--green"
        } else if (status === "ready") {//TO_BE_ACCEPTED
            return "music-dashboard__catalogue__section__light--blue"
        } else {
            return "music-dashboard__catalogue__section__light--red"
        }
    }

    return (
        <div className="music-dashboard__catalogue">
            {noProducts}
            {products && products.map((product) => {
                return (
                    <div className="music-dashboard__catalogue-item">
                        <img className="music-dashboard__catalogue__art" src={`data:image/jpeg;base64,${product.cover_image_string}`} alt="3tone Music"></img>
                        <div className="music-dashboard__catalogue-info">
                            <div className="music-dashboard__catalogue__section--one">
                                <p className="music-dashboard__catalogue__section__title">{product.name}</p>
                                <div className="music-dashboard__catalogue__section__aritsts">
                                    {product.artists.map((e) => { return (<p className="music-dashboard__catalogue__section__artists__aritst">{e.name.toString()}</p>) })}
                                </div>
                            </div>
                            <div className="music-dashboard__catalogue__section--last">
                                <p className={setLightClass(product.state)}>{product.state}</p>
                                <button className="music-dashboard__catalogue__section__button" onClick={() => editProduct(product)}>Edit
                                    <svg className="music-dashboard__catalogue__section__button__icon" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192l-1.412.003l-.002-1.417L20.485 2.1z"></path></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default Catalogue;
//no p descendants

//<p className="music-dashboard__catalogue__header">Title</p>
//<p className="music-dashboard__catalogue__header">Artist</p>

//<p className="music-dashboard__catalogue__header">Label</p>
//<p className="music-dashboard__catalogue__header">Release Date</p>

//<span className="light-container">
//  <p className="music-dashboard__catalogue__status">
//      <span className={setLightClass(product.Published)}>
