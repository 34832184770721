export const dsps = [
    "7digital",
    "ACRCloud",
    "Adaptr",
    "Amazon + Twitch",
    "AMI Entertainment"	,
    "Anghami",
    "Apple Music",
    "AS Distro Reporting"	,
    "Audible Magic - Rights360	"	,
    "Audiomack",
    "AWA",
    "Beatport",
    "Beatsource",
    "Boomplay",
    "Curve",
    "Deezer",
    "Facebook",
    "FLO",
    "Gracenote",
    "iHeartRadio",
    "iMusica",
    "Jaxsta",
    "JioSaavn",
    "JOOX",
    "KKBOX",
    "Napster",
    "NetEase",
    "Pandora",
    "Peloton",
    "QOBUZ",
    "Shazam",
    "Slacker",
    "SoundCloud",
    "Spotify",
    "Tencent",
    "TIDAL",
    "TikTok/Resso"	,
    "TouchTunes",
    "Traxsource",
    "Trebel",
    "Triller",
    "VL Group",
    "YouTube",
    "VEVO - Video",
]