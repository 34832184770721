import React from "react";
import "./ProductTapes.scss";

import ProductTape from "../ProductTape/ProductTape";

const ProductTapes = ({products}) => {
    if (!products || products === [undefined]) {
        return (
            <div className="tape-container">Loading</div>
        )
    }
    return (
        <div className="tape-container" id="products">
            <ul className="tape-products">
                {
                    products && products.map((product) => (
                        <ProductTape key={product.id} product={product.product} priceId={product.id}/>
                    ))
                }
            </ul>
        </div>
    );
}

export default ProductTapes;
