import React from "react";

const Success = () => {
  return (
    <>
      <h1>Thanks for your order!</h1>
      <p>Your order has been received.</p>
    </>
  );
};

export default Success;
