import React, {useState, useEffect, useRef} from "react";
import "./AudioPlayer.scss";
import {useAuth0} from "@auth0/auth0-react";

const AudioPlayer = ({track}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [audioToPlay, setAudioToPlay] = useState('');
    const [playing, setPlaying] = useState(false);
    const audioRef = useRef(null);

    const getAudio = async () => {
        const token = await getAccessTokenSilently();

        const response = await fetch(`https://api.3tonemusic.com/get-audio-file`, {
            method: 'POST',
            credentials:  "same-origin",
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                AssetId: track.id
            })
        });
        if (response.ok) {
            const result = await response.blob();
            if (result) {
                return URL.createObjectURL(result);
            }
        }
    };

    const toggle = () => {
        if (audioToPlay) {
            setPlaying(!playing);
        } else {
            setPlaying(false);
        }
    }

    const playMusic = async () => {
        if (audioToPlay) {
            toggle()
        } else {
            const audio = await getAudio();
            setAudioToPlay(audio);
        }
    };

    const svg = playing ?
        <svg className="audio-player__svg" width="1em" height="1em" viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256s114.6 256 256 256zm-32-320v128c0 17.7-14.3 32-32 32s-32-14.3-32-32V192c0-17.7 14.3-32 32-32s32 14.3 32 32zm128 0v128c0 17.7-14.3 32-32 32s-32-14.3-32-32V192c0-17.7 14.3-32 32-32s32 14.3 32 32z"></path>
        </svg>
        :
        <svg className="audio-player__svg" width="1em" height="1em" viewBox="0 0 32 32">
            <path fill="none"
                  d="M11 23a1 1 0 0 1-1-1V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788l-12 6A1.001 1.001 0 0 1 11 23Z"></path>
            <path fill="currentColor"
                  d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm7.447 14.895l-12 6A1 1 0 0 1 10 22V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788Z"></path>
        </svg>;

    useEffect(() => {
        if (audioRef && audioRef.current) {
            if (playing) {
                audioRef.current.play()
                audioRef.current.onended = () => {
                    setPlaying(false);
                }
            } else {
                audioRef.current.pause()
            }
        }
    }, [playing]);

    useEffect(() => {
        if (audioRef && audioRef.current && audioToPlay) {
            if (!playing) {
                setPlaying(true);
            }
        }
    }, [audioToPlay]);

    useEffect(() => {
        return () => {
            setAudioToPlay(null);
            if (playing) {
                toggle()
            }
        }
    }, []);
    return (
        <button className="audio-player" onClick={playMusic}>
            {
                audioToPlay && (
                    <audio src={audioToPlay} ref={audioRef}></audio>
                )
            }
            <span className={audioToPlay ? '' : 'disabled'}>
                {svg}
            </span>
        </button>
    )
}

export default AudioPlayer;
