import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import LoadCheckout from '../../pages/Checkout/CheckoutStripe';
import {useLoader} from "../LoaderProvider/LoaderProvider";
import {API_ROUTE} from "../../utilities/api";
//import { Redirect } from 'react-router-dom';

export const Subscribe = (props) => {
  const loader = useLoader();
  // Get the lookup key for the price from the previous page redirect.
  const { customer, priceId, planName, showCheckout, discountCode } = props



  // const [clientSecret] = useState(location.state.packageData);

const [subscriptionData, setSubscriptionData] = useState(null);
const [clientToken, setClientToken] = useState(null);
//const [checkout, setCheckout] = useState(false);

//const[priceid] = useState(props.location.state.packageData.priceId);

//const[planName] = useState(props.ocation.state.packageData.planName);

   useEffect(() => {
    createSub(priceId, planName, customer, discountCode);
  }, [])

  const createSub = async (priceid, planName, customer, discountCode) => {

    // console.log(priceid, planName, customer);
    //console.log(discountCode);

    loader.showLoader();

    const {subscriptionId, clientSecret} = await fetch(`${API_ROUTE}/create-subscription`, {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        PriceId: priceid,
        PlanName: planName,
        Customer: customer,
        DiscountCode: discountCode
      }),
    }).then(
        r => r.json()
    ).catch(() => {
      alert("Incorrect Discount code. Please try again");
    });
    loader.hideLoader();
    // console.log("set subscription data");
    // console.log(subscriptionId, clientSecret);
    setSubscriptionData(subscriptionId);
    setClientToken(clientSecret);
  }

   /*if (subscriptionData)
   {
    console.log("set options");
    setOptions(
      {clientSecret: subscriptionData.clientSecret
     });
   }*/

  /* const subscribeButton = checkout ?
   <></>
   :
   <button className="subscribe__link" onClick={showCheckout(subscriptionData, clientToken)}>Proceed to Checkout</button> */

  if (subscriptionData && clientToken)
  {
    return (
      <>
        
        <button className="subscribe__link" onClick={()=>showCheckout(subscriptionData, clientToken)}>Proceed to Checkout</button>
      </>
    )
  }

  return (
    <>
      <p>Loading</p>
    </>
  )
}

//return <NavLink className="subscribe__link" to='/checkout' >Proceed to Checkout</NavLink>
//<LoadCheckout subscriptionData={subscriptionData} clientSecret={clientToken}/>
