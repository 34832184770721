import React, { useEffect, useState } from "react";
import Tooltip from "../Tooltip/Tooltip";
import { fugaGenres } from "../../assets/mockData/genres";
import { countryCodes2 } from "../../assets/mockData/international";
import "./MetadataEditor.scss";
import ArtistsAndContributors from "../ArtistsAndContributors/ArtistsAndContributors";
import moment from 'moment';
import SecondaryGenreSelect from "../SecondaryGenreSelect/SecondaryGenreSelect";
const MetadataEditor = (props) => {

    const { handleChange, data, user, token, labels, addAuthorToProduct, errors, subGenres } = props; //pass down label from MusicDashboard??
    let date = data.consumer_release_date;
    let ogDate = data.original_release_date;
    let preDate = data.preorder_date;
    data.consumer_release_date = moment(date).format("YYYY-MM-DD");
    data.original_release_date = moment(ogDate).format("YYYY-MM-DD");
    data.preorder_date = moment(preDate).format("YYYY-MM-DD");
    if(data.subgenre == null){
        data.subgenre = {};
        data.subgenre.id = "";
    }
   // const [subGenres, setSubGenres] = useState(null);

    // const [labels, setLabels] = useState(null)



    //getLabels
    /*const getLabels = async () => {
        console.log("getlabelsagain:" + user.email);
        await fetch('/get-labels-for-user', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                  'Access-Control-Allow-Origin':'*'
                },
                body: JSON.stringify({
                        UserId: user.email
                }),
              }).then(r => r.json()
              ).then((data) => {
               console.log("labels:", data)
               let lList = [];
               let labs = data.labels;

               labs.forEach(l => {

                   lList.push(l);
               });
               setLabels(lList);
               //data.audio.id is another id?

              })
    }*/

    // const getFugaGenres = async () => { //move to MusicDashboard?
    //     await fetch('https://api.3tonemusic.com/get-subgenres', {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json'
    //             //'x-csrf-token': token
    //         },
    //     }).then(r => r.json()
    //     ).then((data) => {
    //         //console.log("sales:", data)
    //         let subGs = data.subgenre;

    //         let gList = [];
    //         subGs.forEach(g => {
    //             gList.push(g);
    //         });

    //         setSubGenres(gList);

    //         //data.audio.id is another id?

    //     })
    // }

    useEffect(() => {
        if (!labels) {
            //console.log("should get labels with", user)
            // getLabels()

        } else if (labels) {

        }
    }, [])//labels])

    // useEffect(() => {
    //     if (!subGenres) {
    //         getFugaGenres()

    //     } else if (subGenres) {
    //     }
    // }, [])//subGenres])

    useEffect(() => {
        if (data && data.genre && fugaGenres.length > 0) {
            const currentGenreObject = fugaGenres.find(item => item.name === data.genre.name);
            if (currentGenreObject) {
                data.genre['id'] = currentGenreObject.id;
            }
        }
        if (data && data.label && labels.length > 0) {
            const currentLabelObject = labels.find(item => item.name === data.label.name);
            if (currentLabelObject) {
                data.label['fuga_label_id'] = currentLabelObject.fuga_label_id;
            }
        }
    }, [subGenres, data, labels])

    return (
        <>
            <div className="metadata-editor__basic-info">
                <div className="metadata-editor__basic-info--left">
                    <h3 className="metadata-editor__basic-info--left__header">Basic Info</h3>
                    <p className="metadata-editor__basic-info--left__header">Key characteristics to make the product discoverable on DSPs.</p>
                    <label className="metadata-editor__basic-info--left__label">Title<span className="upload-form__span">*</span></label>
                    <input value={data.name} type="text" name="name" className={(errors && errors.name ? 'error__input ' : '') + 'metadata-editor__basic-info--left__input'} onChange={handleChange}></input>
                    {(errors && errors.name) && (<span className="metadata-editor__error__span">Please fill out this field to save your changes</span>)}
                    <label className="metadata-editor__basic-info--left__label">Title Version<Tooltip text="Eg. 'Live', 'Remastered', 'Remix' etc." /></label>
                    <input value={data.release_version} type="text" name="release_version" className="metadata-editor__basic-info--left__input" onChange={handleChange}></input>
                    <label className="metadata-editor__basic-info--left__label">Label<span className="upload-form__span">*</span></label>
                    <div className="upload-form__input">
                        <select value={data.label.fuga_label_id} className="upload-form__input__select" name="label" onChange={handleChange}>
                            <option value="" disabled selected>Select...</option>

                            {labels && labels.map((e) => {

                                return (
                                    <option key={e.fuga_label_id} value={e.fuga_label_id} >{e.name}</option>
                                );
                            })}
                        </select>
                    </div>
                    <label className="metadata-editor__basic-info--left__label">Display Artist<span className="upload-form__span">*</span></label>
                    <input value={data.display_artist} type="text" name="display_artist" className={(errors && errors.display_artist ? 'error__input ' : '') + 'metadata-editor__basic-info--left__input'} onChange={handleChange}></input>
                    {(errors && errors.display_artist) && (<span className="metadata-editor__error__span">Please fill out this field to save your changes</span>)}
                </div>
                <div className="metadata-editor__basic-info--right">
                    <label className="metadata-editor__basic-info--right__label">Release Date<span className="upload-form__span">*</span><Tooltip text="The product will go live to DSPs on this date" /></label>
                    <input className="upload-form__input" value={data.consumer_release_date} required type="date" id="start" name="consumer_release_date" min="1900-01-01" max=""
                        onChange={handleChange} selected={data.consumer_release_date}></input>
                    <label className="metadata-editor__basic-info--right__label">Original Release Date<span className="upload-form__span">*</span><Tooltip text="Used to determine the order of an artist's catalogue, select the same date as the 'Release Date' for a new release." /></label>
                    <input className="upload-form__input" value={data.original_release_date} required type="date" id="start" name="original_release_date" min="1900-01-01" max="" onChange={handleChange}></input>
                    <label className="metadata-editor__basic-info--right__label">Pre-Order Date<Tooltip text="Date the product will be available for pre-order on download stores." /></label>
                    <input className="upload-form__input" value={data.preorder_date} type="date" id="start" name="preorder_date" min="1900-01-01" max="" onChange={handleChange}></input>
                </div>
                <div className="metadata-editor__basic-info--bottom">
                    <label className="upload-form__label">Primary Genre<span className="upload-form__span">*</span></label>
                    <label className="upload-form__label">Secondary Genre</label>
                    <div className="upload-form__input">
                        <select value={data.genre.id} className="upload-form__input__select" required name="genre" onChange={handleChange}>

                            <option value="" disabled selected>Select...</option>
                            {fugaGenres && fugaGenres.map((e) => {
                                return (
                                    <option key={e.id} value={e.id} >{e.name}</option>
                                );
                            })}
                        </select>
                    </div>

                    <SecondaryGenreSelect name={'subgenre'} onChange={handleChange} values={subGenres} selectedGenre={data.subgenre.id}/>
                </div>
            </div>
            <div className="metadata-editor__product-identification">
                <h3 className="metadata-editor__product-identification__header">Product Identification</h3>
                <p className="metadata-editor__product-identification__header">Unique identifiers for royalty reporting and catalog organization.</p>
                <div className="metadata-editor__product-identification__inputs">
                    <label className="upload-form__label">UPC/EAN<span className="upload-form__span">*</span></label>
                    <label className="upload-form__label">Catalog Number</label>
                    <div>
                        <input className={(errors && errors.upc ? 'error__input ' : '') + 'upload-form__input'} value={data.upc.toString()} name="upc" onChange={handleChange}></input>
                        {(errors && errors.upc) && (<span className="metadata-editor__error__span">Please fill out this field to save your changes</span>)}
                    </div>
                    <input className="upload-form__input" value={data.catalog_number?.toString()} name="catalog_number" onChange={handleChange}></input>
                </div>
            </div>
            <div className="metadata-editor__descriptive-metadata">
                <h3 className="metadata-editor__descriptive-metadata__header">Descriptive Metadata</h3>
                <p className="metadata-editor__descriptive-metadata__header">Supporting information to organize the product on DSPs.</p>
                <div className="metadata-editor__descriptive-metadata__inputs">
                    <label className="upload-form__label">Format<span className="upload-form__span">*</span></label>
                    <label className="upload-form__label">Advisory<span className="upload-form__span">*</span><Tooltip text="Select 'Explicit' if there is explicit language" /></label>

                    <div className="upload-form__input">
                        <select value={data.release_format_type} className="upload-form__input__select" name="release_format_type" onChange={handleChange}>
                            <option value="" disabled selected>Select...</option>
                            <option value="SINGLE" >Single</option>
                            <option value="EP" >EP</option>
                            <option value="ALBUM" >Album</option>
                        </select>
                    </div>

                    <div className="upload-form__input">
                        <select value={data.parental_advisory} className="upload-form__input__select" name="parental_advisory" required onChange={handleChange}>
                            <option value="" disabled selected>Select...</option>
                            <option value={false} >None</option>
                            <option value={true} >Explicit</option>
                        </select>
                    </div>

                    <label className="upload-form__label">Language<span className="upload-form__span">*</span><Tooltip text="Language of the lyrics. Select '-No Linguistic Content' for instrumental tracks" /></label>
                    <label className="upload-form__label"><span className="upload-form__span"></span></label>

                    <div className="upload-form__input">
                        <select value={data.language} className="upload-form__input__select" required name="language" onChange={handleChange}>
                            <option value="" disabled selected>Select...</option>
                            {countryCodes2 && countryCodes2.map((e) => {
                                return (
                                    <option key={e.id} value={e.id} >{e.name}</option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="metadata-editor__artists-contributors">
                <h3 className="metadata-editor__artists-contributors__header">Artists & Contributors</h3>
                <ArtistsAndContributors
                    handleChange={handleChange}
                    data={data}
                    addAuthorToProduct={addAuthorToProduct}
                    showArtistsSection={true}
                    showContributorsSection={false}
                    showPublishersSection={false} />
            </div>
            <div className="metadata-editor__rights">
                <h3 className="metadata-editor__descriptive-metadata__header">Rights</h3>
                <p className="metadata-editor__descriptive-metadata__header">Enter the sound recording copyright information for this release. This info is publicly presented on the DSPs as the owner of the recordings.</p>
                <div className="metadata-editor__rights__inputs">
                    <label className="upload-form__label">(C) Info<span className="upload-form__span">*</span></label>
                    <label className="upload-form__label">(C) Year<span className="upload-form__span">*</span></label>
                    <div>
                        <input value={data.c_line_text} type="text" name="c_line_text" className={(errors && errors.c_line_text ? 'error__input ' : '') + 'upload-form__input'} onChange={handleChange}></input>
                        {(errors && errors.c_line_text) && (<span className="metadata-editor__error__span">Please fill out this field to save your changes</span>)}
                    </div>
                    <div>
                        <input value={data.c_line_year} type="number" name="c_line_year" className={(errors && errors.c_line_year ? 'error__input ' : '') + 'upload-form__input'} onChange={handleChange}></input>
                        {(errors && errors.c_line_year) && (<span className="metadata-editor__error__span">Please fill out this field to save your changes</span>)}
                    </div>

                    <label className="upload-form__label">(P) Info<span className="upload-form__span">*</span></label>
                    <label className="upload-form__label">(P) Year<span className="upload-form__span">*</span></label>
                    <div>
                        <input value={data.p_line_text} type="text" name="p_line_text" className={(errors && errors.p_line_text ? 'error__input ' : '') + 'upload-form__input'} onChange={handleChange}></input>
                        {(errors && errors.p_line_text) && (<span className="metadata-editor__error__span">Please fill out this field to save your changes</span>)}
                    </div>
                    <div>
                        <input value={data.p_line_year} type="number" name="p_line_year" className={(errors && errors.p_line_year ? 'error__input ' : '') + 'upload-form__input'} onChange={handleChange}></input>
                        {(errors && errors.p_line_year) && (<span className="metadata-editor__error__span">Please fill out this field to save your changes</span>)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MetadataEditor;

/* <label className="upload-form__label">Rights Holder<span className="upload-form__span">*</span></label>
                    <label className="grid-end"></label>
                    <input value="3tone Music Ltd" type="text" name="Rights" className="upload-form__input" onChange={handleChange}></input>
                    <div className="grid-end"></div>
                    */
