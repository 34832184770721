import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutF from './CheckoutForm.js';

const LoadCheckout = (props) => {

    const {Auth0User, subscriptionId, clientSecret, user, planName} = props;
    const options = {...Auth0User, ...subscriptionId, ...clientSecret, ...user, ...planName}


   // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
   //pk_test_51HSl29AextZ3w9i7rkuM5iHobL6seoAjEVVR7KTgatg1DorsdL9ETaFk9fi5WvIgeyd0fmQLDPREBM6QS2MYS5em00D0xZSkUP
    const stripePromise = loadStripe("pk_live_51HSl29AextZ3w9i7zqjMgvlmuxtjuVztflD8AdvlhJMACzw0q4pDnn4IBI8XvG99KkV2QL4nUX1jOaUgfw7i0EHq00Uk6qVkFg");


  // Get the lookup key for the price from the previous page redirect.
  // console.log(location.state);
  //const [options] = useState(location.state);


  return (
    <>
<hr/>

     <div className='stripeContainer'>
        <Elements stripe={stripePromise} options={options}>
        <CheckoutF/>
        </Elements>
     </div>
    </>
  )
}

export default LoadCheckout;
