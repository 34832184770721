import React, {useEffect, useState} from "react";
import "./TrackMetadata.scss";
import {fugaGenres} from "../../assets/mockData/genres";
import {countryCodes2} from "../../assets/mockData/international";
import Tooltip from "../Tooltip/Tooltip";
import ArtistsAndContributors from "../ArtistsAndContributors/ArtistsAndContributors";
import SecondaryGenreSelect from "../SecondaryGenreSelect/SecondaryGenreSelect";

const TrackMetadata = ({handleTrackChange, track, data, subGenres, label}) => {
    console.log("track in edit", track);
    if(track.subgenre == null){
      track.subgenre = {};
        track.subgenre.id = "";
    }
    if(track.genre == null){
        track.genre = {};
        track.genre.id = "";
    }
    return (
        <div className="track-editor__display">
            <h3 className="track-editor__display__header">Track Info</h3>
            <div className="track-editor__display__grid">
                <label className="upload-form__label">Title<span className="upload-form__span">*</span></label>
                <label className="upload-form__label">Title Version<Tooltip
                    text="Eg. 'Live', 'Remastered', 'Remix' etc."/></label>
                <input value={track.name} type="text" name="name" className="upload-form__input"
                       onChange={handleTrackChange}></input>
                <input value={track.release_version/*?*/} type="text" name="release_version"
                       className="upload-form__input" onChange={handleTrackChange}></input>

                <label className="upload-form__label">Primary Genre<span className="upload-form__span">*</span></label>
                <label className="upload-form__label">Secondary Genre</label>
                <div className="upload-form__input">
                    <select value={track.genre.id} className="upload-form__input__select" required name="genre"
                            onChange={handleTrackChange}>
                        <option value="" disabled selected>Select...</option>
                        {fugaGenres && fugaGenres.map((e) => {
                            return (
                                <option key={e.id} value={e.id}>{e.name}</option>
                            );
                        })}
                    </select>
                </div>
                <SecondaryGenreSelect name={'subgenre'} onChange={handleTrackChange} values={subGenres} selectedGenre={track.subgenre.id}/>
            </div>
            <div className="track-editor__display__quad">
                <label className="upload-form__label">ISRC</label>
                <label className="upload-form__label">Audio Language<span className="upload-form__span">*</span><Tooltip
                    text="Language of the lyrics. Select '-No Linguistic Content' for instrumental tracks"/></label>
                <label className="upload-form__label">Advisory<span className="upload-form__span">*</span><Tooltip
                    text="Select 'Explicit' if there is and explicit language, only select 'Clean' if there is already a corresponding Explicit track"/></label>
                <label className="upload-form__label">Clip Start Time</label>
                <input value={track.isrc} type="text" className="upload-form__input" name="isrc"
                       onChange={handleTrackChange}></input>
                <div className="upload-form__input">
                    <select value={track.audio_locale} className="upload-form__input__select" required name="audio_locale"
                            onChange={handleTrackChange}>
                        <option value="" disabled selected>Select...</option>
                        <option value="ZXX">Instrumental</option>
                        {countryCodes2 && countryCodes2.map((e) => {
                            return (
                                <option key={e.id} value={e.id}>{e.name}</option>
                            );
                        })}
                    </select>
                </div>
                <div className="upload-form__input">
                    <select value={track.parental_advisory} className="upload-form__input__select"
                            name="parental_advisory" required onChange={handleTrackChange}>
                        <option value="" disabled selected>Select...</option>
                        <option value={'NO'}>None</option>
                        <option value={'YES'}>Explicit</option>
                    </select>
                </div>
                <input value={track.clip_start_time} type="text" className="upload-form__input" name="clip_start_time"
                       onChange={handleTrackChange}></input>
            </div>
            <h3 className="track-editor__display__header">Artists & Contributors</h3>
            <ArtistsAndContributors
                isTrack={true}
                handleChange={handleTrackChange}
                data={track}
                parentData={data}
                showArtistsSection={true}
                showContributorsSection={true}
                showPublishersSection={true} label={label}/>
            <div className="metadata-editor__rights">
                <h3 className="metadata-editor__descriptive-metadata__header">Rights</h3>
                <p className="metadata-editor__descriptive-metadata__header">Enter the sound recording copyright
                    information for this track. This info is publicly presented on the DSPs as the owner of the
                    recordings.</p>
                <div className="metadata-editor__rights__inputs">


                    <label className="upload-form__label">(P) Info<span className="upload-form__span">*</span></label>
                    <label className="upload-form__label">(P) Year<span className="upload-form__span">*</span></label>
                    <input value={track.p_line_text} type="text" name="p_line_text" className="upload-form__input"
                           onChange={handleTrackChange}></input>
                    <input value={track.p_line_year} type="number" maxLength={4} name="p_line_year" className="upload-form__input"
                           onChange={handleTrackChange}></input>

                    <label className="grid-end"></label>
                    {/* <input value="3tone Music Ltd" type="text" name="Rights" className="upload-form__input"
                           onChange={handleTrackChange}></input> */}
                    <div className="grid-end"></div>
                </div>
            </div>
        </div>
    )
}

export default TrackMetadata;
