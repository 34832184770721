import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import emailjs from '@emailjs/browser';
import "./Support.scss";

const Support = () => {

    //to be replaced with airtable form?

    const [hasSubmitted, setHasSubmitted] = useState(false);
    //const [userEmail setUserEmail] = useState("");
    //useState for other fields
    const supportEmail = "suppport@3tone.co.uk";

    const response = hasSubmitted ? (<p className="support__form__response">Thanks for your message!</p>) : ``;

    const handleSubmit = () => {
        setHasSubmitted(hasSubmitted => true);

        /*var request = new XMLHttpRequest();
        var url = 'https://your_subdomain.zendesk.com/api/v2/tickets/' + ticket_id + '.json';
        request.open('GET', url, true);
        request.setRequestHeader("Authorization", "Bearer " + access_token);
        request.send();*/
    }

    return (
        <div className="support">
           <iframe  src="https://airtable.com/embed/shrQzPB9mZ4HTNJZO?backgroundColor=gray" frameborder="0"  width="100%" height="100%"></iframe>
        </div>
    )
}

export default Support;