import React, { useState, useRef } from "react";
import "./ArtUploader.scss";
import { useAuth0 } from "@auth0/auth0-react";
import {useLoader} from "../LoaderProvider/LoaderProvider";
import {API_ROUTE} from "../../utilities/api";

const ArtUploader = (props) => {

    const loader = useLoader();
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const { buttonText, buttonFunction, userType, userTypeReq, handleChange, popup, setArtToUpload } = props;
    const isClosable = true;

    const [file, setFile] = useState([]);
    const [trackInfoList, setTrackInfoList] = useState([]);
    const [imageIndex, setIm] = useState(null);
    const data = new FormData();
    const wrapperRef = useRef(null);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');
    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        //newFile.isUploading = true;
        if (newFile) {
            const newTrack = newFile;
            setArtToUpload(newTrack);

            console.log("files:", newTrack);
            //upload to backend

            data.append(
                newFile.name,
                newFile,
                newFile.name
            )
            //axios??

            //handleChange({"target": {"name": "Tracks", "value": fileList}});
        }

    }

    const Upload = async () => {
        const token = await getAccessTokenSilently();

        loader.showLoader();

        await fetch(`${API_ROUTE}/create-asset/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: data
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();
        }).catch(() => {
            loader.hideLoader();
        })
    }

    const closeButton = isClosable ? (
        <button className="popup-container__popup__close" onClick={() => props.setTrigger("popup-minimised")}>
            <svg width="1em" height="1em" viewBox="0 0 64 64"><path fill="currentColor" d="M62 10.571L53.429 2L32 23.429L10.571 2L2 10.571L23.429 32L2 53.429L10.571 62L32 40.571L53.429 62L62 53.429L40.571 32z"></path></svg>
        </button>
    ) : ("")

    return (
        <div className={`popup-container ${popup}`}>
            <div className="popup-container__popup">
                <h4 className="popup-container__popup__header"></h4>
                <div className="upload-form__uploader"

                    ref={wrapperRef}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}>
                    <h3 className="upload-form__label">Upload Image</h3>
                    <svg className="upload-form__uploader__drop-zone__svg" aria-hidden="true" role="img" id="footer-sample-full" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M15.213 6.639c-.276 0-.546.025-.809.068C13.748 4.562 11.716 3 9.309 3c-2.939 0-5.32 2.328-5.32 5.199c0 .256.02.508.057.756a3.567 3.567 0 0 0-.429-.027C1.619 8.928 0 10.51 0 12.463S1.619 16 3.617 16H8v-4H5.5L10 7l4.5 5H12v4h3.213C17.856 16 20 13.904 20 11.32c0-2.586-2.144-4.681-4.787-4.681z"></path></svg>
                    <div className="upload-form__uploader__drop-zone__text">Drag and Drop files to upload or</div>
                    <div className="upload-form__uploader__input-container">
                        <input className="upload-form__uploader__input-container__input"

                            accept="file_extension|.png|.jpg"
                            multiple type="file"
                            autoComplete="off"
                            tabIndex="-1"
                            onChange={onFileDrop}
                        ></input>
                        <button className="upload-form__uploader__input-container__button">
                            <svg className="upload-form__uploader__nput-container__button__svg" role="img" id="footer-sample-full" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="currentColor" d="M16 2A14.172 14.172 0 0 0 2 16a14.172 14.172 0 0 0 14 14a14.172 14.172 0 0 0 14-14A14.172 14.172 0 0 0 16 2Zm8 15h-7v7h-2v-7H8v-2h7V8h2v7h7Z"></path><path fill="none" d="M24 17h-7v7h-2v-7H8v-2h7V8h2v7h7v2z"></path></svg>
                            Upload
                        </button>
                    </div>
                </div>
                <p className="popup-container__popup__text">Audio uploaded must be in one of the following formats: <br /> -HD WAV 24-bit @ 44.1, 48, 88.2, 96, or 192kHz <br /> -HD FLAC 24-bit @ 44.1, 48, 88.2, 96, or 192kHz <br /> -WAV (16bit @ 44.1kHz) <br />  -FLAC (16bit @ 44.1kHz)</p>
                {closeButton}
                <button className="popup-container__popup__button" onClick={Upload}>Done</button>
            </div>
        </div>
    )
}

export default ArtUploader;

//<a className="popup-container__popup__link" href={link}>{linkText}</a>
//<Link to={link}>{linkText}</Link>
