import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Popup.scss";
import {useAuth0} from "@auth0/auth0-react";
import {API_ROUTE} from "../../utilities/api";

import image from "./popup-keypad.png";

const Popup = (props) => {
    const { getAccessTokenSilently } = useAuth0();

    const {header, text, link, linkText, upgradeLink, buttonText, buttonFunction, userType, userTypeReq, isClosable, isRoyalty, user} = props;

    const checkUser = () =>{
        if(!userTypeReq){
            return true;
        } else if(userType === userTypeReq){
            return true;
        } else {
            return false;
        }
    }

    const closeButton = isClosable ? (
        <button className="popup-container__popup__close" onClick={()=>props.setTrigger("")}>
            <svg width="1em" height="1em" viewBox="0 0 64 64"><path  fill="#000" d="M62 10.571L53.429 2L32 23.429L10.571 2L2 10.571L23.429 32L2 53.429L10.571 62L32 40.571L53.429 62L62 53.429L40.571 32z"></path></svg>
        </button>
    ) : ("");

    // const loadPortal = async () => {
    //     const token = await getAccessTokenSilently();


    //     const session = await fetch(`${API_ROUTE}/create-customer-portal-session`, {

    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`,
    //             'Access-Control-Allow-Origin':'*'
    //         },
    //         body: JSON.stringify({
    //             id: user.customer_id,
    //             redirect_url: window.location.href
    //         })
    //     }).then(r => r.json());
    //     if (session) {
    //         window.location.href = session;
    //     }
    // }


    return (

        <div className="popup">
            <div className="popup__container">
                
            <button className="popup__container__close" onClick={()=>props.setTrigger("")}>
                <svg width="1em" height="1em" viewBox="0 0 64 64"><path fill="#000" d="M62 10.571L53.429 2L32 23.429L10.571 2L2 10.571L23.429 32L2 53.429L10.571 62L32 40.571L53.429 62L62 53.429L40.571 32z"></path></svg>
            </button>
                <div className="popup__container__image">
                    <img src={image} />
                </div>
                <div className="popup__container__content">
                    <h3>{header}</h3>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default Popup;

