import React, {createContext, useContext, useState} from "react";
import "./LoaderProvider.scss";
import logoWhite from "../../assets/img/3tone_White.png";

export const LoaderContext = createContext();
export const useLoader = () => useContext(LoaderContext);

const LoaderProvider = ({children}) => {
    const [isShowingLoader, setIsShowingLoader] = useState(false);
    const showLoader = () => {
        setIsShowingLoader(true);
    }
    const hideLoader = () => {
        setIsShowingLoader(false);
    }
    return(
        <LoaderContext.Provider
            value={{
                showLoader,
                hideLoader,
            }}>
            {children}
            {
                isShowingLoader && (
                    <div className="loader">
                        <span className="spinner spinner-1">
                            <img className="loader-logo" src={logoWhite} alt="3tone Music"></img>
                        </span>
                    </div>
                )
            }
        </LoaderContext.Provider>
    )
}

export default LoaderProvider;
