import React, { useState, useRef } from "react";
import Tooltip from "../Tooltip/Tooltip";
import "./Tracklist.scss";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import {useLoader} from "../LoaderProvider/LoaderProvider";
import {useAuth0} from "@auth0/auth0-react";
import {API_ROUTE} from "../../utilities/api";

const TrackList = ({ handleChange, data, editTrack, addTrack }) => {
    const loader = useLoader();
    const [tracks, setTracks] = useState(data.assets);
    console.log("tracks in list", tracks);
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const handleAdd = () => {
        let newTracks = [];
        if (data.assets) {
            if (tracks) {
                newTracks = tracks;
            }
        }
        addTrack()
    }

    const handleDelete = async (track) => {
        loader.showLoader();
        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/delete-asset-from-product`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                asset_id: track.id,
                product_id: data.id
            }),
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();
            if (data) {
                let newTracks = [...tracks];
                const currentIndex = newTracks.findIndex(item => item.id === track.id);
                newTracks.splice(currentIndex, 1);
                setTracks(newTracks);
                handleChange(currentIndex, true);
            }
        }).catch(() => {
            loader.hideLoader();
        })
    }

    const onDragEnd = async (index, track, data) => {
        console.log(track)
        const trackId = track.id;
        const productId = data.id;
        const newPosition = dragOverItem.current;
        let newTracks = [...tracks];
        const draggedItemContent = newTracks.splice(dragItem.current, 1)[0];
        newTracks.splice(dragOverItem.current, 0, draggedItemContent);

        dragItem.current = null;
        dragOverItem.current = null;
        setTracks(newTracks);

        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/update-asset-sequence`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                assetId: trackId,
                id: productId,
                sequence: newPosition,
            }),
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();
            if (data) {
                const changeData = {
                    target: {
                        name: 'assets',
                        value: newTracks
                    }
                }
                handleChange(changeData, false);
            }
        })
    }

    return (
        <div className="tracklist">
            <div className="tracklist__header">
                <p>In order to create additional tracks, audio files must be uploaded in one of the supported formats.<Tooltip text="-HD WAV 24-bit @ 44.1, 48, 88.2, 96, or 192kHz <br> -HD FLAC 24-bit @ 44.1, 48, 88.2, 96, or 192kHz <br> -WAV (16bit @ 44.1kHz) <br> -FLAC (16bit @ 44.1kHz)" /></p>
                <button className="tracklist__header__button" onClick={() => handleAdd()}>ADD A TRACK</button>
            </div>

            <div className="tracklist__top">
                <h3 className="tracklist__top__header">#</h3>
                <h3 className="tracklist__top__header">Track Title</h3>

                <h3 className="tracklist__top__header">Display Artist</h3>
                <h3 className="tracklist__top__header">ISRC</h3>

                <h3 className="tracklist__top__header--end"></h3>
            </div>
            <div className="tracklist__tracklist">
                {tracks && tracks.map((track, index) => {
                    return (
                        <div key={track.id} className="tracklist__tracklist__track" draggable onDragStart={(e) => dragItem.current = index} onDragEnter={(e) => dragOverItem.current = index} onDragEnd={() => onDragEnd(index, track, data)} onDragOver={(e) => e.preventDefault}>
                            <p>{index + 1}</p>
                            <p>{track.name}</p>

                            <p>
                                {
                                    track && track.artists && track.artists.map((item, index) => (
                                        <span>
                                            { item.name + ((track.artists.length - 1) === index ? '' : ', ')}
                                        </span>
                                    ))
                                }
                            </p>
                            <p>{track.isrc}</p>

                            <button className="tracklist__tracklist__track__button" >
                                <div className={`tracklist__tracklist__track__button__button-bank`}>
                                    <button className="tracklist__tracklist__track__button__button-bank__button" onClick={() => editTrack(track, index, false)}>Edit</button>
                                    <AudioPlayer track={track} />
                                    <button className="tracklist__tracklist__track__button__button-bank__button" onClick={() => handleDelete(track)}>Delete</button>
                                </div>
                            </button>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default TrackList;
//onClick={()=> showBank(buttonBankClasses[index], index)}
//<svg className="tracklist__tracklist__track__button__icon" xmlns="http://www.w3.org/2000/svg" width="41.606" height="41.617" viewBox="0 0 41.606 41.617"><path id="Settings" d="M42.671,25.3a5.354,5.354,0,0,1,3.435-4.995,21.222,21.222,0,0,0-2.568-6.187,5.426,5.426,0,0,1-2.178.466,5.342,5.342,0,0,1-4.887-7.519A21.158,21.158,0,0,0,30.3,4.5a5.349,5.349,0,0,1-9.99,0,21.222,21.222,0,0,0-6.187,2.568,5.342,5.342,0,0,1-4.887,7.519,5.249,5.249,0,0,1-2.178-.466,21.691,21.691,0,0,0-2.557,6.2,5.352,5.352,0,0,1,.011,9.99A21.222,21.222,0,0,0,7.079,36.5a5.344,5.344,0,0,1,7.054,7.054,21.346,21.346,0,0,0,6.187,2.568,5.34,5.34,0,0,1,9.968,0,21.222,21.222,0,0,0,6.187-2.568A5.349,5.349,0,0,1,43.527,36.5,21.346,21.346,0,0,0,46.1,30.309,5.379,5.379,0,0,1,42.671,25.3ZM25.4,33.96a8.668,8.668,0,1,1,8.668-8.668A8.666,8.666,0,0,1,25.4,33.96Z" transform="translate(-4.5 -4.5)" fill="#393939"/></svg>
