import React from "react";
import "./ServicesBlock.scss";
import servicesImg from '../../assets/img/services.jpg';
import servicesImgMobile from '../../assets/img/mobile.jpg';
import arrowL from '../../assets/img/arrow-left.png';
import arrowR from '../../assets/img/arrow-right.png';

const ServicesBlock = () => {

    return (
        <div className="services-container">
            <div className="services-container__header">
                <h1>Services</h1>
                <p>PLEASE CONTACT <a className="services-container__link" href="mailto:SUPPORT@3TONEMUSIC.CO.UK">SUPPORT@3TONEMUSIC.CO.UK</a> FOR MORE DETAILS</p>
                <img className="scribble-arrow scribble-arrow-left" src={arrowL} alt="3toneMusic"></img>
            <img className="scribble-arrow scribble-arrow-right" src={arrowR} alt="3toneMusic"></img>
            </div>
            <div className="services-container__img">
                <img className="services-content" src={servicesImg} alt="3toneMusic"></img>
                <img className="services-content-mobile" src={servicesImgMobile} alt="3toneMusic"></img>
            </div>

        </div>
    )
}

export default ServicesBlock;
