import React, {useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Success from "./pages/Success/Success";

import "./App.css";
import MyAccount from "./pages/MyAccount/MyAccount";
import Support from "./pages/Support/Support";

import Legal from "./pages/Legal/Legal";
import PageNotFound from "./pages/404/404";

import AccountSettings from "./pages/AccountSettings/AccountSettings";
import MusicDashboard from "./pages/MusicDashboard/MusicDashboard";
import AnalyticsRoyalties from "./pages/AnalyticsRoyalties/AnalyticsRoyalties";
import Royalties from "./pages/Royalties/Royalties";
import Addons from "./pages/Addons/Addons";
import {Upgrade} from "./pages/Upgrade/Upgrade";
import MySubscription from "./pages/MySubscription/MySubscription";
import AccountSupport from "./pages/AccountSupport/AccountSupport";
import AccountCancelled from "./pages/AccountCancelled/AccountCancelled";
import PaymentFailed from "./pages/PaymentFailed/PaymentFailed";
import PurchaseSuccess from "./pages/PurchaseSuccess/PurchaseSuccess";

import TestPage from "./pages/TestPage/TestPage";

import Prices from "./components/Prices/Prices";
import { Register } from "./pages/Register/Register";
import LoadCheckout from "./pages/Checkout/CheckoutStripe";
import "./App.scss";
import LoaderProvider from "./components/LoaderProvider/LoaderProvider";
import {secureApiFormatData, secureApiJSON} from "./utilities/api";
import {useAuth0} from "@auth0/auth0-react";

const App = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const setToken = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      secureApiJSON.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      secureApiFormatData.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  };
  useEffect(() => {
    setToken()
  }, [isAuthenticated]);
  return (
      <LoaderProvider>
        <Layout>
          <Routes>
            <Route path="/success" element={<Success />} />
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/my-account" element={<MyAccount />}>
              <Route index element={<MySubscription />} />
              <Route path="settings" element={<AccountSettings />} />
              <Route path="dashboard" element={<MusicDashboard />} />
              <Route path="analytics" element={<AnalyticsRoyalties />} />
              <Route path="Royalties" element={<Royalties />} />
              <Route path="add-ons" element={<Addons />} />
              <Route path="account-support" element={<AccountSupport />} />
              <Route path="upgrade" element={<Upgrade />} />
            </Route>
            <Route path="/upgrade" element={<Upgrade />} />
            <Route path="/prices" element={<Prices/>} />
            <Route path="/register-lite" element={<Register product="Lite"/>} />
            <Route path="/register-plus" element={<Register product="Plus"/>} />
            <Route path="/register-pro" element={<Register product="Pro"/>} />
            <Route path="/checkout" element={<LoadCheckout />} />
            <Route path="/payment-success" element={<PurchaseSuccess />} />
            <Route path="/payment-failed" element={<PaymentFailed />} />
            <Route path="/support" element={<Support />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="cancelled" element={<AccountCancelled />} />
            <Route path="*" element={<PageNotFound />} />

          </Routes>
        </Layout>
      </LoaderProvider>
  );
};

export default App;
