import userEvent from "@testing-library/user-event";
import React, { useState, useEffect } from "react";
import "./Royalties.scss";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Popup from "../../components/Popup/RoyaltyPopup";
import {API_ROUTE} from "../../utilities/api";
import Dollar from "./dollar.png";
import graph from "./graph.png";
import image from "./popup-image.png";
import keypad from "./popup-keypad.png";

const Royalties = () => {

    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [ balance, setBalance ] = useState(0);
    const [ transactions, setTransactions ] = useState([]);
    const [noContractId, setNoContractId] = useState("");
    const location = useLocation();
    const [userEmail, setUserEmail] = useState(null);
    // const email = "kaylembillett@hotmail.com";
    const [popup, setPopup] = useState("");

    const [showElement, setShowElement] = useState(false);

    const [showReport, setShowReport] = useState(false);
 

    const requestRoyalties = async () => {
        const token = await getAccessTokenSilently();

        // console.log(userEmail);
        
        await fetch(`${API_ROUTE}/request-royalties`, {

          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            email: userEmail
          }),
        }).then(r => r.json()
        ).then((data) => {
            // console.log(data);
        setShowElement(false);
         if(data == true)
         {
            setPopup("success");
         }
         else
         {
            setPopup("fail");
         }

        })
    }

    const handlePopup = (popup) => {
        switch (popup) {
            case "success":
                return <Popup header="You've requested your royalties!" text="Your royalties have been requested, we'll send you an email soon with more information about payouts."  setTrigger={setPopup}  isClosable={true}/>
            case "failure":
                return <Popup header="Oh no!" text="That didn't work, please try again"  setTrigger={setPopup}  isClosable={true} />
            default:
            break;
        }
    }
    // const getUserRoyalties = async () => {
    //     const token = await getAccessTokenSilently();
    //     //console.log(token);

    //     await fetch(`${API_ROUTE}/get-balance-for-user`, {

    //       method: 'post',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${token}`
    //       },
    //       body: JSON.stringify({
    //         email: email
    //       }),
    //     }).then(r => r.json()
    //     ).then((data) => {
    //      console.log("balance:", balance)
    //      if(data.balance == 0.0)
    //      {
    //         setNoContractId("You don't have any royalties yet... check back soon!");
    //         console.log(noContractId);
    //      }
    //      setBalance(data)
    //      console.log("balance:", balance);

    //     })
    //     //console.log(dbUser);


    //     await fetch(`${API_ROUTE}/get-transactions-for-user`, {

    //         method: 'post',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Authorization': `Bearer ${token}`
    //         },
    //         body: JSON.stringify({
    //           email: email
    //         }),
    //       }).then(r => r.json()
    //       ).then((data) => {
    //        console.log("transactions:", data)
    //        setTransactions(data)
    //        console.log(transactions);
    //       })
    // }

    // useEffect(() => {
    //     if(email){
    //         getUserRoyalties();
    //     }
    //     //useLocation().state = toneUser;
    // }, [])

    useEffect(() => {
        if (user) {
          setUserEmail(user.email);
        }
      }, [user]);
    
    //   useEffect(() => {
    //     if (userEmail) {
    //      requestRoyalties();
    //     }
    //   }, [userEmail])




    return(
        <>
        <div className="royalties">
            <h1>Royalties</h1>
            <div className="royalties__options">
            

                <div className="royalties__options__item" onClick={() => setShowElement(!showElement)}>
                    <div className="royalties__options__item__card">
                        <img src={Dollar} />
                    </div>
                    <div className="royalties__options__item__title">
                        royalty requests
                    </div>
                </div>


                <div className="royalties__options__item" onClick={() => setShowReport(!showReport)}>
                    <div className="royalties__options__item__card">
                        <img className="royalties__options__item__card__graph" src={graph} />
                    </div>
                    <div className="royalties__options__item__title">
                        Access your royalty reports
                    </div>
                </div>
            </div>

            <div className="royalties__footer">
                <p>If you have any questions regarding your royalties or payout please get in touch with us via <a href="mailto:royalties@3tonemusic.com">royalties@3tonemusic.com</a></p>
            </div>
        </div>

       

        <div style={{display: showElement ? 'block' : 'none'}} className="popup"> 
            <div className="popup__container" >
                
            <button className="popup__container__close" onClick={() => setShowElement(!showElement)}>
                <svg width="1em" height="1em" viewBox="0 0 64 64"><path fill="#000" d="M62 10.571L53.429 2L32 23.429L10.571 2L2 10.571L23.429 32L2 53.429L10.571 62L32 40.571L53.429 62L62 53.429L40.571 32z"></path></svg>
            </button>
                <div className="popup__container__image">
                    <img src={keypad} />
                </div>
                <div className="popup__container__content">
                    <h3>Royalty Requests</h3>
                    <p>We pay out royalties on the 1st of every month. To receive your royalties, please put through your request by the 23rd of the previous month.</p>
                    <p>We have a minimum payout threshhold of £50, if your monthly statement does not meet this your royalties will roll over to the next month. 
                        Once your request has been processed, you will receive an email notification with instructions on how to withdraw your balance.
                    </p>
                    <div>
                        <button onClick={requestRoyalties} >Request royalties</button>
                    </div>

                </div>
            </div>
        </div>

        <div style={{display: showReport ? 'block' : 'none'}} className="popup"> 
            <div className="popup__container" >
                
            <button className="popup__container__close" onClick={() => setShowReport(!showReport)}>
                <svg width="1em" height="1em" viewBox="0 0 64 64"><path fill="#000" d="M62 10.571L53.429 2L32 23.429L10.571 2L2 10.571L23.429 32L2 53.429L10.571 62L32 40.571L53.429 62L62 53.429L40.571 32z"></path></svg>
            </button>
                <div className="popup__container__image">
                    <img src={image} />
                </div>
                <div className="popup__container__content">
                    <h3>Access your royalty reports</h3>
                    <p>You will be taken to our royalty partner Curve where you can access all your royalty data to date, download CSV and PDF reports, create bespoke reports, view what royalties have been paid out to you and view your closing/available balance.</p>
                    <div>
                        <a href="https://app.curveroyaltysystems.com/login" target="_blank">Access reports</a>
                    </div>
                </div>
            </div>
        </div>
        


        
        {handlePopup(popup)}
        </>
    )
}

export default Royalties;
