import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import "./SelectElementLine.scss";
import Switch from '@mui/material/Switch';
import { connect } from "redux-zero/react";
import appActions from "../../store/actions/appActions";
import { roles, publishing_houses } from "../../utilities/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import {API_ROUTE} from "../../utilities/api";

const mapToProps = ({ artistsFullList, peopleFullList }) => ({ artistsFullList, peopleFullList });

const SelectElementLine = (props) => {

    const {
        handleSelectChange,
        name,
        index,
        obj,
        removeElement,
        artistsFullList,
        peopleFullList,
        elementItemsList,
        contributorsList,
        publishersList,
        addAuthorToProduct,
        handleIsPrimaryChanged,
        clearElementName,
        assetId,
        getContributors,
        getPublishers,
        publishingHouses, label
    } = props;
    const location = useLocation();
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState({ value: obj.name, label: obj.name, id: obj.id, primary: obj.primary, role: obj.role, publishing_house: obj.publishing_house });
    const [roleValue, setRoleValue] = useState()
    const [newObj, setNewObj] = useState(obj);

    const [options, setOptions] = useState([])
    const [peopleRoles, setPeopleRoles] = useState([])
    const [publishingHousesList, setPublishingHousesList] = useState(publishingHouses)

    const handleNameChange = (e) => {
        let tempObj = { ...newObj };
        tempObj.name = e.target.value;
        setNewObj(tempObj)
        handleSelectChange(newObj);
    }

    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });

    const handleCreate = async (inputValue) => {
        if (name === "Artists") {
            await handleCreateArtist(inputValue)
        }
        if (name === "Contributors") {
            await handleCreatePeople(inputValue)
        }
        if (name === "Writers") {
            await handleCreatePeople(inputValue)
        }
    }

    const handleCreateArtist = async (inputValue) => {
        setIsLoading(true);

        const token = await getAccessTokenSilently();

        let formData = new FormData();
        formData.append("name", inputValue);
        formData.append("proprietary_id", inputValue);
        const user = location.state;
        formData.append("user", user.email);


        await fetch(`${API_ROUTE}/create-artist`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept': "multipart/form-data"
            },
            body: formData,
        }).then(r => r.json()
        ).then((data) => {
            setIsLoading(false);
            if (data.is_request_successful) {
                let newOption = { name: data.artist_result.name, label: data.artist_result.name, id: data.artist_result.id, primary: true }
                setValue(newOption)
                addAuthorToProduct({ id: newOption.id, name: newOption.name, primary: newOption.primary })
                toast.success(data.response_message)
            } else {
                toast.error(data.response_message)
            }
        }).catch((error) => {
            console.log('error');
            console.log(error);
            toast.error("Error while updating artist")
            setIsLoading(false);
        })
    }

    const handleCreatePeople = async (inputValue) => {
        setIsLoading(true);
        console.log(inputValue)

        const token = await getAccessTokenSilently();

        let formData = new FormData();
        formData.append("name", inputValue);
        formData.append("label_id", label );


        await fetch(`${API_ROUTE}/create-people`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept': "multipart/form-data"
            },
            body: formData,
        }).then(r => r.json()
        ).then((data) => {
            setIsLoading(false);
            if (data.is_request_successful) {
                console.log(data.person_result);
                let newOption = { name: data.person_result.name, label: data.person_result.name, id: data.person_result.id, role: "", publishing_house: "" }
                setValue(newOption)
                console.log(newOption);
                setOptions(...options, newOption)
                //addAuthorToProduct({ id: newOption.id, name: newOption.name, primary: newOption.primary })
                toast.success(data.response_message)
            } else {
                toast.error(data.response_message)
            }
        }).catch((error) => {
            console.log('error');
            console.log(error);
            toast.error("Error while updating people")
            setIsLoading(false);
        })
    }

    const addContributorToAsset = async (inputValue) => {
        setIsLoading(true);
        console.log('adding new contributor value');
        console.log(inputValue)

        const token = await getAccessTokenSilently();

        let formData = new FormData();
        formData.append("person", inputValue.id);
        formData.append("role", inputValue.role);
        formData.append("assetId", assetId)

        await fetch(`${API_ROUTE}/add-contributor-to-asset`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept': "multipart/form-data"
            },
            body: formData,
        }).then(r => r.json()
        ).then((data) => {
            setIsLoading(false);
            if (data.is_request_successful) {
                console.log(data);
                getContributors(assetId);
                //let newOption = { name: data.person_result.name, label: data.person_result.name, id: data.person_result.id, role: ""}
                //setValue(newOption)
                //console.log(newOption);
                //setOptions(...options, newOption)
                //addAuthorToProduct({ id: newOption.id, name: newOption.name, primary: newOption.primary })
                toast.success(data.response_message)
            } else {
                toast.error(data.response_message)
            }
        }).catch((error) => {
            console.log('error');
            console.log(error);
            toast.error("Error while updating people")
            setIsLoading(false);
        })
    }

    const addPublisherToAsset = async (inputValue) => {
        setIsLoading(true);
        console.log('adding new publisher value')
        console.log(inputValue)

        const token = await getAccessTokenSilently();

        let formData = new FormData();
        formData.append("person", inputValue.id);
        formData.append("publishing_house", inputValue.publishing_houseId);
        formData.append("assetId", assetId)

        await fetch(`${API_ROUTE}/add-publisher-to-asset`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept': "multipart/form-data"
            },
            body: formData,
        }).then(r => r.json()
        ).then((data) => {
            setIsLoading(false);
            if (data.is_request_successful) {
                console.log(data);
                getPublishers(assetId);
                toast.success(data.response_message)
            } else {
                toast.error(data.response_message)
            }
        }).catch((error) => {
            console.log('error');
            console.log(error);
            toast.error("Error while updating people")
            setIsLoading(false);
        })
    }

    const handleChange = (newValue) => {

        if (name === "Artists") {
            if (newValue == null) {
                setValue({ name: "", primary: true })
                clearElementName(name, index)
            } else {
                setValue(newValue)
                let newElement = artistsFullList.find(x => x.id === newValue.id)
                console.log(newElement);
                addAuthorToProduct({ ...newElement, "primary": true })
            }
        }

        if (name === "Contributors") {
            if (newValue == null) {
                setValue({ name: "", role: "" })
                clearElementName(name, index)
            } else {
                setValue(newValue)
                let newElement = peopleFullList.find(x => x.id === newValue.id)
                console.log(newElement);
                addAuthorToProduct({ ...newElement, "role": "" })
            }
        }
    }

    const handleRoleChange = (newValue) => {
        console.log('inside')
        let val = { ...value }
        val.role = newValue.value
        console.log(val);
        addContributorToAsset(val)
    }

    const handlePublishingHouseChange = (newValue) => {
        console.log('inside')
        let val = { ...value }
        val.publishing_house = newValue.value
        val.publishing_houseId = newValue.id
        console.log(val);
        addPublisherToAsset(val)
    }

    const handleIsPrimaryChange = (e) => {
        let tempObj = { ...value };
        tempObj.primary = e.target.checked;
        setValue(tempObj)
        handleIsPrimaryChanged(index)
    }

    useEffect(() => {
        if (name === "Artists") {
            if (artistsFullList.length > 0) {
                const currentOptions = artistsFullList.map(element => {
                    return { value: element.name, label: element.name, id: element.id, primary: true }
                });
                setOptions(currentOptions);
            }
        }
    }, [artistsFullList])

    useEffect(() => {
        if (name === "Contributors") {
            const currentRolesOptions = roles.map(element => {
                return { value: element, label: element }
            })
            console.log('currentRolesOptions');
            console.log(currentRolesOptions);
            setPeopleRoles(currentRolesOptions)
            if (contributorsList.length > 0) {
                console.log('contributorsList');
                console.log(contributorsList);
                const currentOptions = contributorsList.map(element => {
                    return { value: element.name, label: element.name, id: element.id }
                });
                setOptions(currentOptions);
            }
        }
    }, [contributorsList])

    useEffect(() => {
        console.log('value check');
        console.log(value);
        if (name === "Writers") {
            const currentPublishingHousesOptions = publishingHouses.map(element => {
                return { value: element.name, label: element.name, id: element.id }
            })
            console.log('currentPublishingHousesOptions');
            console.log(currentPublishingHousesOptions);
            setPublishingHousesList(currentPublishingHousesOptions)
            if (publishersList.length > 0) {
                console.log('publishersList');
                console.log(publishersList);
                const currentOptions = publishersList.map(element => {
                    return { value: element.name, label: element.name, id: element.id }
                });
                setOptions(currentOptions);
            }
        }
    }, [publishersList])


    return (
        <div className="dragable-container__dragable">
            <CreatableSelect className="dragable-container__dragable__input"
                isClearable
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={handleChange}
                onCreateOption={handleCreate}
                options={options}
                value={value} />
            {/* <input value={newObj.name} type="text" className="dragable-container__dragable__input" name={name} onChange={handleNameChange}></input> */}

            {name === "Artists" &&
                <div className="dragable-container__dragable__input">
                    <Switch value={value.primary} checked={value.primary} onChange={handleIsPrimaryChange} />
                </div>
            }

            {name === "Contributors" &&
                <CreatableSelect className="dragable-container__dragable__input"
                    isClearable
                    isDisabled={value.role != ""}
                    isLoading={isLoading}
                    isSearchable={true}
                    onChange={handleRoleChange}
                    options={peopleRoles}
                    value={{ label: value.role, name: value.role }} />
            }


            {name === "Writers" &&
                <CreatableSelect className="dragable-container__dragable__input"
                    isClearable
                    isDisabled={value.publishing_house != ""}
                    isLoading={isLoading}
                    isSearchable={true}
                    onChange={handlePublishingHouseChange}
                    options={publishingHousesList}
                    value={{ label: value.publishing_house, name: value.publishing_house }} />
            }





            <button className="dragable-container__dragable__button" onClick={() => removeElement(name, index)}>
                <svg viewBox="0 0 1000 1000" width="100%" preserveAspectRatio="xMidYMid meet"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M640 320L512 192 320 384 128 192 0 320l192 192L0 704l128 128 192-192 192 192 128-128L448 512 640 320z" />
                </svg>
            </button>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
        </div>
    )
}

export default connect(mapToProps, appActions)(SelectElementLine);
