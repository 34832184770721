import React, { useState, useEffect } from "react";
import Prices from "../../components/Prices/Prices";
import ProductTapes from "../../components/ProductTapes/ProductTapes";
import ShaderBackground from "../../components/ShaderBackground/ShaderBackgroundStarter";
import ThreeDBackground from "../../components/3Dbackground/ThreeDBackgroundC";//R
import "./Upgrade.scss";
import { useLocation } from "react-router-dom";
import ProductTape from "../../components/ProductTape/ProductTape";

export const Upgrade = ()=> {

    const [length, setLength] = useState(6)
    const [products, setProducts] = useState("ALL")

    const location = useLocation();
    const user = location.state;

    useEffect(() => {
        
        console.log(user);
    
        if(user){ // && user.has_active_subscription === true
            if(user.plan_name === "TEST - Lite Plan"){
                setLength(4)
                setProducts("PLUS&PRO")
            } else if(user.plan_name === "TEST - Plus plan" || user.plan_name === "TEST - Pro Plan" ){
                setLength(2)
                setProducts("PRO") 
            } // PRO additional artist upgrade here
        }
        console.log(length)
    }, [])
    
 
    return(
        <div className="upgrade-page">
            <ShaderBackground />
            <Prices upgrade={true} length={length} products={products}/>
        </div>
    )
}

//export default Upgrade;

/*

<>
        <div className="homepage-container__top">
        <ThreeDBackground />
        </div>

        */