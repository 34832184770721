import React, { useState, useRef } from "react";
import "./UploadForm.scss";
import Tooltip from "../Tooltip/Tooltip";

const UploadFormStep1 = (props) => {

    const {handleChange, data, labels} = props;
    const dummyUser =    {"labels" : ["3tone", "label2", "3Soul"]}
    let labelsForUser = [];
    labelsForUser = labels;
    console.log(labelsForUser);

    return(
        <div className="upload-form--step1">
            <div className="upload-form--step1__left">
                <label className="upload-form__label">Format<span className="upload-form__span">*</span></label>
                <div className="upload-form__input">
                    <select value={data.release_format_type} className="upload-form__input__select" name="release_format_type" onChange={handleChange}>
                        <option value="" disabled>Select...</option>
                        <option value="Single">Single</option>
                        <option value="EP" >EP</option>
                        <option value="Album" >Album</option>
                    </select>
                </div>
                <label className="upload-form__label">Title<span className="upload-form__span">*</span></label>
                <input value={data.name} type="text" name="name" className="upload-form__input" onChange={handleChange}></input>
                <label className="upload-form__label">Title Version<Tooltip text="Eg. 'Live', 'Remastered', 'Remix' etc."/></label>
                <input value={data.release_version/**/} type="text" name="release_version" className="upload-form__input" onChange={handleChange}></input>
                <label className="upload-form__label">Label<span className="upload-form__span">*</span></label>
                <div className="upload-form__input">
                    <select value={data.label.fuga_label_id} className="upload-form__input__select" name="label" onChange={handleChange}>
                        <option value="" disabled>Select...</option>
                        {
                            labelsForUser && labelsForUser.map((e) => (
                                <option value={e.fuga_label_id} >{e.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="upload-form--step1__right">

            </div>
        </div>
    )
}

export default UploadFormStep1;

//<h2 className="upload-form__header">General</h2>
//onChange={onFileDrop}
