import ShaderBackground from "../../components/ShaderBackground/ShaderBackgroundStarter";
import React from "react";

const Login = () => {

    return(
        <ShaderBackground />
    )
}

export default Login;