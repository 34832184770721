import React, { useEffect, useState } from "react";
import "./MusicDashboard.scss";
import UploadForm from "../../components/UploadForm/UploadForm";
import Catalogue from "../../components/Catalogue/Catalogue";
import ReleaseEditor from "../../components/ReleaseEditor/ReleaseEditor";
import Popup from "../../components/Popup/Popup";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoader } from "../../components/LoaderProvider/LoaderProvider";
import appActions from "../../store/actions/appActions";
import { connect } from "redux-zero/react";

import { ToastContainer, toast } from 'react-toastify';
import {API_ROUTE} from "../../utilities/api";

const mapToProps = ({ currentUser }) => ({ currentUser });

const MusicDashboard = ({ setArtistsFullList, setPeopleFullList, currentUser }) => {
    const loader = useLoader();
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [noProducts, setNoProducts] = useState("");
    const [labels, setLabels] = useState(null);
    const [currentLabel, setCurrentLabel] = useState(null);
    const [products, setProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [fullListOfArtists, setfullListOfArtists] = useState(null);
    const [subGenres, setSubGenres] = useState(null);
    const [page, setPage] = useState("Releases");
    const [isHandlingSubmit, setIsHandlingSubmit] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);

    useEffect(() => {
        if (!subGenres) {
            getFugaGenres()

        }
    }, [subGenres])

    const getFugaGenres = async () => {

        await fetch(`${API_ROUTE}/get-subgenres`, {

            method: 'GET',
            headers: {
                'Content-Type': 'application/json'

                //'x-csrf-token': token

            },
        }).then(r => r.json()
        ).then((data) => {
            let subGs = data.subgenre;
            let gList = [];
            subGs.forEach(g => {
                gList.push(g);
            });
            setSubGenres(gList);
        })
    }

    const getLabels = async () => {
        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/get-labels-for-user/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
                UserId: currentUser.email
            }),
        }).then(r => r.json()
        ).then((data) => {
            setCurrentLabel(data.labels[0])
            let labels3 = data.labels;
            let lList = [];
            labels3.forEach(l => {
                lList.push(l);
            });
            setLabels(lList);
        })
    }

    const getProducts = async (labelId) => {
        const token = await getAccessTokenSilently();
        loader.showLoader();

        await fetch(`${API_ROUTE}/get-products-for-label/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                id: labelId
            }),
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();
            setProducts(data)
            if (data && data.product && data.product.length > 0) {
                setNoProducts('');
            } else {
                setNoProducts("No products yet, upload a new release to get started! :) ");
            }
        }).catch(() => {
            loader.hideLoader();
        })
    }

    const getFullListOfArtists = async () => {
        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/get-artists/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                email: currentUser.email})
        }).then(r => r.json()
        ).then((data) => {
            setfullListOfArtists(data)
            if (data && data.artist) {
                setArtistsFullList(data.artist)
            }
        })
    }

    const getFullListOfPeople = async (currentLabel) => {
        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/get-people/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                label_id: currentLabel.fuga_label_id
            }),
        }).then(r => r.json()
        ).then((data) => {
            if (data && data.person) {
                setPeopleFullList(data.person)
            }
        })
    }
    useEffect(() => {
        if (currentUser) {
            if (!labels) {
                getLabels()
            }
        }
    }, [currentUser])
    useEffect(() => {
        if (labels) {
            if (currentLabel) {
                console.log(currentUser.id);
                getProducts(currentUser.email);
                getFullListOfArtists()
                getFullListOfPeople(currentLabel)
            } else {
                setCurrentLabel(labels[0])
            }
        }
    }, [labels, currentLabel])

    const mockUser = {
        subscription_status: "active"
    }
    const editProduct = (data) => {
        setProduct(data);
        setPage("Editor")
    }



    const removeFromTheList = (id) => {
        const currentProducts = {...products};
        const productList = [...currentProducts.product];
        const deletingIndex = productList.findIndex(item => item.id === id);
        productList.splice(deletingIndex, 1);
        currentProducts['product'] = productList;
        setProducts(currentProducts)
    }

    const addProductToList = (product) => {
        const currentProducts = {...products};
        const productList = [...currentProducts.product];
        productList.unshift(product);
        currentProducts['product'] = productList;
        setProducts(currentProducts)
    }

    const handleDeleteProduct = async () => {
        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/delete-product`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ProductId: product.id
            }),
        }).then(r => r.json()
        ).then((data) => {
            removeFromTheList(product.id);
            setPage("Releases")

        }).catch((error) => console.log('error:::', error))
    }

    const handlePublishProduct = async () => {
        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/publish-product`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                product_id: product.id,
                email: currentUser.email
            }),
        }).then(r => r.json()
        ).then((data) => {
            if (data.is_request_successful) {
                toast.success(data.response_message)
            } else {
                toast.error(data.response_message)
            }
            //setPage("Releases")



        }).catch((error) => console.log('error:::', error))
    }

    const handleTakeDownProduct = async () => {
        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/takedown`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ProductId: product.id
            }),
        }).then(r => r.json()
        ).then((data) => {
            // setProducts(data)

        }).catch((error) => console.log('error:::', error))
    }

    const handleRequest = (request) => {
        switch (request) {
            case "save":
                //handleSave(product);//needs to use product in release editor
                break;
            case "delivery":
                //(product);
                break;
            default:
                break;
        }
    }

    const handleSaveRelease = () => {
        setIsHandlingSubmit(true)
    }

    const handleHeaderButtons = () => {
        if (product.Published === "incomplete") {
            return (
                <button className="music-dashboard__header__button--hidden" onClick={() => handleRequest("delivery")}>Save Changes</button>
            )
        } else if (product.Published === "ready") {
            return (
                <>
                    <button className="music-dashboard__header__button" onClick={() => handleRequest("delivery")}>Request Delivery</button>
                    <button className="music-dashboard__header__button--hidden" onClick={() => handleRequest("delivery")}>Save Changes</button>
                </>
            )
        } else if (product.Published === "published") {
            return (
                <>
                    <button className="music-dashboard__header__button" onClick={() => handleRequest("takedown")}>Request Takedown</button>
                    <button className="music-dashboard__header__button--hidden" onClick={() => handleRequest("delivery")}>Save Changes</button>
                </>
            )
        }
    }

    const className = `music-dashboard__content ${page}`

    useEffect(() => {
        if (page === "Editor") {
            if (!product.state) {
                product.state = "PENDING";
            }
        }
    }, [page, product])

    return (
        <>
            {
                mockUser.subscription_status !== "active" ? (
                    <div className="music-dashboard restricted">
                        <Popup header="Sorry! This page is restricted." user={currentUser} text="You don't seem to have an active subscription on this account." upgradeLink={true} linkText="Upgrade Here" />
                    </div>
                ) : (
                    <>
                        {
                            page === "Releases" && (
                                <div className="music-dashboard">
                                    <div className="music-dashboard__header">
                                        <h1 className="music-dashboard__header__title">Music Catalogue:</h1>
                                        <button className="music-dashboard__header__button" onClick={() => setPage("Upload")}>UPLOAD NEW RELEASE +</button>
                                    </div>
                                    <div className={className}>
                                        <div className="music-dashboard__catalogue-wrapper">
                                            <Catalogue editProduct={editProduct} products={products && products.product} noProducts={noProducts} />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            page === "Upload" && (
                                <div className="music-dashboard">
                                    <div className="music-dashboard__header">
                                        <h1>Upload:</h1>
                                        <button className="music-dashboard__header__button" onClick={() => setPage("Releases")}>CANCEL</button>
                                    </div>
                                    <div className={className}>
                                        <div className="music-dashboard__upload-form">
                                            <UploadForm subGenres={subGenres} labels={labels} editProduct={editProduct} user={currentUser} addProductToList={addProductToList} />

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            page === "Editor" && (
                                <div className="music-dashboard">
                                    <div className="music-dashboard__header">
                                        <h1>Edit Release:</h1>
                                        <button class="music-dashboard__header__button"onClick={handleTakeDownProduct}>
                                            Takedown
                                        </button>
                                        <button class="music-dashboard__header__button" onClick={handlePublishProduct}>
                                           Publish
                                        </button>
                                        <button class="music-dashboard__header__button" onClick={handleDeleteProduct}>
                                            Delete
                                        </button>
                                        {
                                            showSaveButton && (
                                                <button className="music-dashboard__header__button"
                                                        onClick={handleSaveRelease}>
                                                    Save Changes
                                                </button>
                                            )
                                        }

                                        {handleHeaderButtons()}
                                        <button className="music-dashboard__header__button" onClick={() => setPage("Releases")}>CANCEL</button>
                                    </div>
                                    <div className={className}>
                                        <ReleaseEditor
                                            setShowSaveButton={setShowSaveButton}
                                            setIsHandlingSubmit={setIsHandlingSubmit}
                                            isHandlingSubmit={isHandlingSubmit}
                                            data={product}
                                            user={currentUser}
                                            labels={labels}
                                            subGenres={subGenres} />
                                    </div>
                                    <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored" />
                                </div>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default connect(mapToProps, appActions)(MusicDashboard);

//<button className="music-dashboard__header__button" onClick={() => handleRequest("update")}>Request Update</button>
