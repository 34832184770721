import React, {useState, useEffect} from 'react';
import ProductTapes from '../ProductTapes/ProductTapes';
import "./Prices.scss";
import { PRICES_IDS} from "../../utilities/constants";
import {API_ROUTE} from "../../utilities/api";

export const VISIBLE_PRICES = [
    PRICES_IDS.LITE,
    PRICES_IDS.PLUS,
    PRICES_IDS.PRO,
];

const Prices = () => {
    const [prices, setPrices] = useState([]);
    const [priceProducts, setPriceProducts] = useState([]);

    const fetchPrices = async () => {

        const response = await fetch(`${API_ROUTE}/config`);

        if (response && response.ok) {
            const result = await response.json();
            if (result && result.prices) {
                setPrices(result.prices);
            }
        }
    };
    function sortArray(a, b) {
        if (a.product.name < b.product.name){
            return -1;
        }
        if (a.product.name > b.product.name){
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        fetchPrices();
    }, [])

    useEffect(() => {
        const uniquePriceProducts = [];
        prices.forEach(price => {
            if (VISIBLE_PRICES.includes(price.id)) {
                uniquePriceProducts.push(price);
            }
        });
        uniquePriceProducts.sort(sortArray);
        setPriceProducts(uniquePriceProducts)
    }, [prices])

    return (
        <>
            {
                prices.length > 0 ? (
                    <ProductTapes products={priceProducts} />
                ) : (
                    <h2 className='prices-not-found'>No packages found</h2>
                )
            }
        </>
    )
}

export default Prices;
