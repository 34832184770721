import React, { useState, useEffect } from "react";
import "./ArtistsAndContributors.scss";
import Tooltip from "../Tooltip/Tooltip";
import Dragable from "../Dragable/Dragable";
import SelectElementLine from "../SelectElementLine/SelectElementLine";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoader } from "../LoaderProvider/LoaderProvider";
import { ToastContainer, toast } from 'react-toastify';
import {API_ROUTE} from "../../utilities/api";

const ArtistsAndContributors = (props) => {

    const { isTrack,
        handleChange,
        data,
        parentData,
        showArtistsSection,
        showContributorsSection,
        showPublishersSection, label } = props;
        console.log("data in artists and contributors", data);
    const [artists, setArtists] = useState(data.artists);//check for at least one main artist
    const [contributors, setContributors] = useState([]);//data.Contributors)
    const [newContributors, setNewContributors] = useState([])
    const [writers, setWriters] = useState([]);//data.Writers)//being reset here???
    const [publishingHouses, setPublishingHouses] = useState([])

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const loader = useLoader();

    useEffect(() => {
        if (artists && artists.length > 0) {
            data.artists = artists.filter(x => x.name && x.name.length > 0)
        }
    }, [artists])

    useEffect(() => {
        if (showContributorsSection) {
            console.log('inside useEffect getContributors');
            getContributors(data.id)
        }
        if (showPublishersSection) {
            console.log('inside useEffect getPublishers');
            getPublishingHouses()
            getPublishers(data.id)
        }
    }, [])


    const getContributors = async (assetId) => {
        const token = await getAccessTokenSilently();
        loader.showLoader();

        await fetch(`${API_ROUTE}/get-contributors-for-asset/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                assetId: assetId
            }),
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();
            console.log("contributorsForAsset---->>:>>", data)
            let newCollection = data.map((e, index) => {
                let newElem = { role: e.role, name: e.person.name, id: e.id, person: e.person.id }
                console.log('newElem')
                console.log(newElem)
                return newElem
            })
            setContributors(newCollection)
        }).catch(() => {
            loader.hideLoader();
        })
    }

    const getPublishers = async (assetId) => {
        const token = await getAccessTokenSilently();
        loader.showLoader();

        await fetch(`${API_ROUTE}/get-publishers-for-asset/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                assetId: assetId
            }),
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();
            console.log("publishersForAsset---->>:>>", data)
            let newCollection = data.map((e, index) => {
                let newElem = { publishing_house: e.publishing_house.name, name: e.composer_or_lyricist.name, id: e.id, person: e.composer_or_lyricist.id }
                console.log('newElem')
                console.log(newElem)
                return newElem
            })
            setWriters(newCollection)
        }).catch(() => {
            loader.hideLoader();
        })
    }

    const getPublishingHouses = async () => {
        const token = await getAccessTokenSilently();

        await fetch(`${API_ROUTE}/get-publishing-houses`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
        }).then(r => r.json()
        ).then((data) => {
            console.log("fullListOfPublishingHouses---->>:>>", data)
            if (data && data.publishing_house) {
                setPublishingHouses(data.publishing_house)
            }
        })
    }


    const addDragable = (type) => {

        if (type === artists) {

            setArtists([...artists, { name: "", primary: true }]);
        }
        else if (type === contributors) {
            if (contributors) {
                setContributors([...contributors, { name: "", role: null }]);
            }
            else {
                setContributors([{ name: "", primary: true }]);
            }

        } else if (type === writers) {
            if (writers) {
                setWriters([...writers, { name: "", publishing_house: null }]);
            }
        }
    }
    const removeDragable = (type, index) => {
        if (type === "Contributors") {
            let newContributors = [...contributors];
            newContributors.splice(index, 1);
            setContributors(newContributors);

        } else if (type === "Writers") {
            let newWriters = [...writers];
            newWriters.splice(index, 1);
            setWriters(newWriters);

        } else if (type === "Artists") {
            let newArtists = [...artists];
            newArtists[index] = { name: "", primary: true }
            setArtists(newArtists);
        }
    }

    const removeElement = (type, index) => {
        if (type === "Contributors") {
            console.log('contributors[index]');
            console.log(contributors[index]);
            let newContributors = [...contributors];
            newContributors.splice(index, 1);
            setContributors(newContributors);

        } else if (type === "Writers") {
            console.log('writers[index]');
            console.log(writers[index]);
            let newWriters = [...writers];
            newWriters.splice(index, 1);
            setWriters(newWriters);

        } else if (type === "Artists") {
            let newArtists = [...artists];
            newArtists.splice(index, 1)
            setArtists(newArtists);
        }
    }

    const removeContributorFromAsset = async (name, index) => {
        let assetId = data.id
        loader.showLoader();
        console.log('removeContributorFromAsset')
        console.log(contributors[index])

        const token = await getAccessTokenSilently();

        let formData = new FormData();
        formData.append("person", contributors[index].id);
        formData.append("assetId", assetId)

        await fetch(`${API_ROUTE}/delete-contributor-from-asset`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept': "multipart/form-data"
            },
            body: formData,
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();
            if (data.is_request_successful) {
                console.log(data);
                toast.success(data.response_message)
                getContributors(assetId)
            } else {
                toast.error(data.response_message)
            }
        }).catch((error) => {
            console.log('error');
            console.log(error);
            loader.hideLoader();
        })
    }

    const removePublisherFromAsset = async (name, index) => {
        let assetId = data.id
        loader.showLoader();
        console.log('removePublisherFromAsset')
        console.log(writers[index])

        const token = await getAccessTokenSilently();

        let formData = new FormData();
        formData.append("person", writers[index].id);
        formData.append("assetId", assetId)

        await fetch(`${API_ROUTE}/delete-publisher-from-asset`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept': "multipart/form-data"
            },
            body: formData,
        }).then(r => r.json()
        ).then((data) => {
            loader.hideLoader();
            if (data.is_request_successful) {
                console.log(data);
                toast.success(data.response_message)
                getPublishers(assetId)
            } else {
                toast.error(data.response_message)
            }
        }).catch((error) => {
            console.log('error');
            console.log(error);
            loader.hideLoader();
        })
    }

    const clearElementName = (type, index) => {
        if (type === "Contributors") {
            let newContributors = [...contributors];
            newContributors[index] = { name: "", role: "" }
            setContributors(newContributors);

        } else if (type === "Writers") {
            let newWriters = [...writers];
            newWriters.splice(index, 1);
            setWriters(newWriters);

        } else if (type === "Artists") {
            let newArtists = [...artists];
            newArtists[index] = { name: "", primary: true }
            setArtists(newArtists);
        }
    }


    const handleDragableChange = (e, name, index) => {
        //console.log("name:", name, "index:", index, "incoming change:", e)
        //console.log("new contrib:", e);
        let newArr;
        if (name === 'Artists') {
            newArr = artists;
        } else if (name === 'Writers') {
            newArr = writers;
        } else if (name === 'Contributors') {
            newArr = contributors;
        }

        newArr[index] = e;
        //setArtists(...artists, artists[index]=e);
        //console.log("newArr of", name, newArr);
        //let newData = {...formData, [e.target.name]: e.target.value}
        //console.log("new:", newData);
        //setFormData(newData);
        //console.log("formData:", formData.updated);

        handleChange({ "target": { "name": name, "value": newArr } });
    }

    const handleSelectChange = (newElement) => {
        if (artists.length === 0) {
            //data.artists[0] = {...newElement, primary:true}
            setArtists([newElement])
        } else {
            //data.artists.push({...newElement, primary:true})
            setArtists([...artists.slice(0, artists.length - 1), newElement])
        }
    }

    const handleIsPrimaryChanged = (changedItemIndex) => {
        let oldArtistsValue = [...artists];
        oldArtistsValue[changedItemIndex].primary = !oldArtistsValue[changedItemIndex].primary;
        setArtists(oldArtistsValue);
    }

    const copyFromRelease = () => {
        setArtists(parentData.artists);
        setWriters(parentData.Writers);
        setContributors(parentData.Contributors);
    }

    const copyHolder = isTrack ?
        <div className="artists-and-contributors__inheritor">
            <p className="artists-and-contributors__inheritor__description">Copy Artists & Contributors from release data to all tracks.</p>
            <button className="artists-and-contributors__inheritor__button" onClick={() => copyFromRelease()}>Copy Info</button>
        </div>
        : ""

    return (
        <div className="artists-and-contributors">
            <div className="artists-and-contributors__header-container">
                <p className="artists-and-contributors__header-container__description">
                    Enter credits for all relevant Artists & Contributors at the product level. Artists & Contributors should only be credited here if they contribute to every track within the product. Artists & Contributors can be customized for each track within the Track Editor. Please add at least one "Main Artist".
                </p>
                {copyHolder}
            </div>

            {showArtistsSection &&
                <div className="artists-and-contributors__dragable-container">
                    <h3 className="artists-and-contributors__dragable-container__header">Artists<Tooltip text="Each arist must be individually credited with a role assingment. Any Release with 4 or more Main Artists and/or Remixers will be flagged as a 'Various artists' compilation on DSPs." /></h3>
                    <div className="artists-and-contributors__dragable-container__subheaders">
                        <label className="artists-and-contributors__dragable-container__subheaders__label">Name</label>
                        <label className="artists-and-contributors__dragable-container__subheaders__label">Is Primary Artist?</label>
                    </div>
                    {artists && artists.map((e, index) => {
                        return (
                            <SelectElementLine
                                handleSelectChange={handleSelectChange}
                                name={"Artists"}
                                index={index}
                                obj={e}
                                removeElement={removeElement}
                                elementItemsList={data.artists}
                                key={e.id}
                                addAuthorToProduct={handleSelectChange}
                                handleIsPrimaryChanged={handleIsPrimaryChanged}
                                clearElementName={clearElementName} />
                        );
                    })}
                    <button className="artists-and-contributors__dragable-container__button" onClick={() => addDragable(artists)}>Add +</button>
                </div>
            }

            {showContributorsSection &&
                <div className="artists-and-contributors__dragable-container">
                    <h3 className="artists-and-contributors__dragable-container__header">Additional Contributors<Tooltip text="Enter credits for studio presonnel and additional contributors here. Do not add them unless they contributed to every track on this release." /></h3>
                    <div className="artists-and-contributors__dragable-container__subheaders">
                        <label className="artists-and-contributors__dragable-container__subheaders__label">Name</label>
                        <label className="artists-and-contributors__dragable-container__subheaders__label">Role</label>
                    </div>
                    {contributors && contributors.map((e, index) => {
                        return (
                            <SelectElementLine
                                handleSelectChange={handleSelectChange}
                                name={"Contributors"}
                                index={index}
                                obj={e}
                                removeElement={removeContributorFromAsset}
                                contributorsList={[]}
                                key={e.id}
                                addAuthorToProduct={handleSelectChange}
                                handleIsPrimaryChanged={handleIsPrimaryChanged}
                                clearElementName={clearElementName}
                                assetId={data.id}
                                getContributors={getContributors} label={label} />
                        );
                    })}
                    <button className="artists-and-contributors__dragable-container__button" onClick={() => addDragable(contributors)}>Add +</button>
                </div>
            }

            {showPublishersSection &&
                <div className="artists-and-contributors__dragable-container">
                    <h3 className="artists-and-contributors__dragable-container__header">Publishers<Tooltip text="Enter credits for writers and Publishers here. Do not add them unless they contributed to every track on this release." /></h3>
                    <div className="artists-and-contributors__dragable-container__subheaders">
                        <label className="artists-and-contributors__dragable-container__subheaders__label">Name</label>
                        <label className="artists-and-contributors__dragable-container__subheaders__label">Publishing house</label>
                    </div>
                    {writers && writers.map((e, index) => {
                        return (
                            <SelectElementLine
                                handleSelectChange={handleSelectChange}
                                name={"Writers"}
                                index={index}
                                obj={e}
                                removeElement={removePublisherFromAsset}
                                publishersList={[]}
                                key={e.id}
                                addAuthorToProduct={handleSelectChange}
                                handleIsPrimaryChanged={handleIsPrimaryChanged}
                                clearElementName={clearElementName}
                                assetId={data.id}
                                getPublishers={getPublishers}
                                publishingHouses={publishingHouses} />
                        );
                    })}
                    <button className="artists-and-contributors__dragable-container__button" onClick={() => addDragable(writers)}>Add +</button>
                </div>
            }

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
        </div>
    )
}

export default ArtistsAndContributors;

//<h2 className="artists-and-contributors__header-container__header">Artists & Contributors</h2>
