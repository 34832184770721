export const PRICES_IDS = {
    LITE: 'price_1MgpPiAextZ3w9i7fKoq0uLf',
    PLUS: 'price_1MgpQFAextZ3w9i7W5cY4eBW',
    PRO: 'price_1MeKNPAextZ3w9i753aumsKQ',
};

export const roles = [
    "A_R_MANAGER",
    "A_R_ADMINISTRATOR",
    "ACTOR",
    "ADAPTER",
    "AGENT",
    "ARRANGER",
    "ART_DIRECTION",
    "ARTIST_MANAGEMENT",
    "ASST_COMPOSER",
    "ASST_CONDUCTOR",
    "ASST_DIRECTOR",
    "ASST_MASTERING_ENGINEER",
    "ASST_MIXING_ENGINEER",
    "ASST_PRODUCER",
    "ASST_RECORDING_ENGINEER",
    "ASST_SOUND_ENGINEER",
    "AUTHOR",
    "BAND",
    "CAMERA_OPERATOR",
    "CHOIR",
    "CHOIR_CONDUCTOR",
    "CHOREOGRAPHER",
    "CHORUS",
    "CHORUS_MASTER",
    "CINEMAPHOTOGRAPHER",
    "CO_PRODUCER",
    "COMPOSER",
    "COMPUTER_GRAPHIC_CREATOR",
    "CONDUCTOR",
    "CONTRIBUTING_ARTIST",
    "COSTUME_DESIGNER",
    "CREATIVE_DIRECTOR",
    "DANCER",
    "DIRECTOR",
    "DJ",
    "EDITOR",
    "ENGINEER",
    "ENSEMBLE",
    "EXECUTIVE_PRODUCER",
    "FEATURING",
    "GAFFER",
    "GUEST_VOCALS",
    "IMMERSIVE_MIXING_ENGINEER",
    "KEY_GRIP",
    "LIBRETTIST",
    "LIGHTING_DIRECTOR",
    "LINER_NOTES",
    "LEAD_GUITAR",
    "LEAD_VIOLIN",
    "LEAD_VOCALS",
    "LYRICIST",
    "MASTERING_ENGINEER",
    "MC",
    "MIXER",
    "MIXING_ENGINEER",
    "MUSICAL_DIRECTOR",
    "NARRATOR",
    "ORCHESTRA",
    "ORCHESTRATOR",
    "PERFORMER",
    "PLAYWRIGHT",
    "POST_PRODUCER",
    "PRODUCER",
    "PRODUCTION_ASST",
    "PROGRAMMER",
    "RECORDING_ENGINEER",
    "REMIXER",
    "SET_DESIGNER",
    "SOLOIST",
    "SOUND_EDITOR",
    "SOUND_ENGINEER",
    "SPOKEN_WORD",
    "STRINGS_CONDUCTOR",
    "STUDIO_PERSONNEL",
    "TONMEISTER",
    "TRANSLATOR",
    "VIDEO_DIRECTOR",
    "VIDEO_PRODUCER",
    "VISUAL_EFFECTS_TECHNICIAN",
    "WRITER"
]

export const publishing_houses = [
    {
        "id": 6982016853,
        "name": "Blake Gregory",
        "organization_id": 6383630256
    },
    {
        "id": 6975739421,
        "name": "UPL/Sentric Music",
        "organization_id": 6383630256
    },
    {
        "id": 6965281157,
        "name": "Mike Blue",
        "organization_id": 6383630256
    },
    {
        "id": 6965199525,
        "name": "UPL Publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6965199119,
        "name": "Palestone Publishing Ltd",
        "organization_id": 6383630256
    },
    {
        "id": 6964927036,
        "name": "Bitchpop Records",
        "organization_id": 6383630256
    },
    {
        "id": 6960197882,
        "name": "Aaron Bhambri",
        "organization_id": 6383630256
    },
    {
        "id": 6958829789,
        "name": "Rob Lewis",
        "organization_id": 6383630256
    },
    {
        "id": 6923175796,
        "name": "Bartłomiej Dzikowski",
        "organization_id": 6383630256
    },
    {
        "id": 6911521787,
        "name": "3 Tone Publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6885669954,
        "name": "Hubris Music Publishing LTD",
        "organization_id": 6383630256
    },
    {
        "id": 6881548197,
        "name": "Adam Forrester",
        "organization_id": 6383630256
    },
    {
        "id": 6880573495,
        "name": "Saguaro Sunrise Publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6880270622,
        "name": "Jeorge Lunguinho de Souza Filho",
        "organization_id": 6383630256
    },
    {
        "id": 6880060170,
        "name": "Edu Camargo",
        "organization_id": 6383630256
    },
    {
        "id": 6876631974,
        "name": "Estelar Produções Artísticas Ltda.",
        "organization_id": 6383630256
    },
    {
        "id": 6854473684,
        "name": "ENSO",
        "organization_id": 6383630256
    },
    {
        "id": 6848483264,
        "name": "Joelle Einerson",
        "organization_id": 6383630256
    },
    {
        "id": 6848481872,
        "name": "Shamina Isaac",
        "organization_id": 6383630256
    },
    {
        "id": 6848480901,
        "name": "Life's A Journey Music",
        "organization_id": 6383630256
    },
    {
        "id": 6840602901,
        "name": "Copyright Control | Copyright Control",
        "organization_id": 6383630256
    },
    {
        "id": 6797767280,
        "name": "Mutinhimira Media",
        "organization_id": 6383630256
    },
    {
        "id": 6793474855,
        "name": "LAMCO (ACEMLA)",
        "organization_id": 6383630256
    },
    {
        "id": 6793472467,
        "name": "amble walks publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6630620296,
        "name": "Administered by Songs of Kobalt Music Publishing (BMI);Alexei Misoul Music (BMI) ;Dan Book Music Inc (BMI) / EMI Blackwood Music Inc (BMI);FRNDzone Music;NASHTY Music / Sony ATV (BMI);Sleep When I M Rich Music / Sony ATV (BMI)",
        "organization_id": 6383630256
    },
    {
        "id": 6630617859,
        "name": "Administered by Songs of Kobalt Music PublishingBMI);Alexei Misoul Music (BMI) ;Dan Book Music Inc (BMI) / EMI Blackwood Music Inc (BMI);FRNDzone Music;NASHTY Music / Sony ATV (BMI);Sleep When I M Rich Music / Sony ATV (BMI)",
        "organization_id": 6383630256
    },
    {
        "id": 6630586954,
        "name": "Karima Francis",
        "organization_id": 6383630256
    },
    {
        "id": 6630574143,
        "name": "Jay-Boy Music Corp.",
        "organization_id": 6383630256
    },
    {
        "id": 6630573895,
        "name": "Carlin Music Delaware LLC",
        "organization_id": 6383630256
    },
    {
        "id": 6630571168,
        "name": "Round Hill Carlin",
        "organization_id": 6383630256
    },
    {
        "id": 6630569936,
        "name": "BMG Rights Management (UK) Limited/Davray Music Limited",
        "organization_id": 6383630256
    },
    {
        "id": 6630569462,
        "name": "Warner Chappell Music Inc.",
        "organization_id": 6383630256
    },
    {
        "id": 6630557484,
        "name": "Wa Drossa",
        "organization_id": 6383630256
    },
    {
        "id": 6630537912,
        "name": "Music By Tough Cut",
        "organization_id": 6383630256
    },
    {
        "id": 6630498910,
        "name": "Sivan Langer",
        "organization_id": 6383630256
    },
    {
        "id": 6630482779,
        "name": "JacobOwen Music (SESAC)",
        "organization_id": 6383630256
    },
    {
        "id": 6630468748,
        "name": "Ultra Publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6630454514,
        "name": "Universal Music Publishing France",
        "organization_id": 6383630256
    },
    {
        "id": 6630452789,
        "name": "Sentric Music Publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6630415583,
        "name": "Edition Sir 'e' Us Music / Sony Music Publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6630389593,
        "name": "Infamous Stiff Music BMI",
        "organization_id": 6383630256
    },
    {
        "id": 6630325239,
        "name": "Rxy;Tawanda Mupfunya",
        "organization_id": 6383630256
    },
    {
        "id": 6630297546,
        "name": "Nicolas Ramaget",
        "organization_id": 6383630256
    },
    {
        "id": 6630255104,
        "name": "Univrsal Music Publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6630253223,
        "name": "OGS Records",
        "organization_id": 6383630256
    },
    {
        "id": 6630216192,
        "name": "Palestone Publishing",
        "organization_id": 6383630256
    },
    {
        "id": 6630178572,
        "name": "Anders Jensen",
        "organization_id": 6383630256
    },
    {
        "id": 6630149264,
        "name": "Minimums",
        "organization_id": 6383630256
    },
    {
        "id": 6630134075,
        "name": "3p Songs;Edition Shen Ming / Premium Blend Music Productions / BMG Ufa",
        "organization_id": 6383630256
    },
    {
        "id": 6630133762,
        "name": "Edition Egoizm / Premium Blend Music Productions GmbH / BMG Ufa;Edition Shen Ming / Premium Blend Music Productions / BMG Ufa",
        "organization_id": 6383630256
    }
]
