import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import {useLoader} from "./LoaderProvider/LoaderProvider";

const ACCOUNT_PATH_NAMES = [
    '/my-account',
    '/my-account/details',
    '/my-account/details',
    '/my-account/settings',
    '/my-account/dashboard',
    '/my-account/upgrade',
    '/upgrade',
    '/my-account/analytics',
    '/my-account/royalties',
    '/my-account/add-ons',
    '/my-account/account-support',
];

const Layout = ({children}) => {
    const loader = useLoader();
    const [isAccount, setIsAccount] = useState(false);
    const location = useLocation();

    const {
        isLoading,
        error
    } = useAuth0();

    useEffect(() => {
        if (isLoading) {
            loader.showLoader();
        } else {
            loader.hideLoader();
        }
    }, [isLoading]);

    useEffect(() => {
        const pathname = location.pathname;
        setIsAccount(ACCOUNT_PATH_NAMES.includes(pathname));
    }, [location]);
    return (
        <>
            {
                !error ? (
                    <>
                        {
                            !isAccount && (
                                <Header page={location.pathname}/>
                            )
                        }
                        <main>{children}</main>
                        <Footer/>
                    </>
                ) : (
                    <div>Oops... {error.message}</div>
                )
            }
        </>
    );
};

export default Layout;
