import createStore from "redux-zero";

const initialState = {
    artistsFullList: [],
    peopleFullList: [],
    currentUser: null,
};
const store = createStore(initialState);

export default store;
