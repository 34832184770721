import React, {useState, useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import "./Register.scss";
import {Subscribe} from '../../components/Subscribe/Subscribe';
import LoadCheckout from '../Checkout/CheckoutStripe';
import {useLoader} from "../../components/LoaderProvider/LoaderProvider";
import {API_ROUTE} from "../../utilities/api";
import { PRICES_IDS } from "../../utilities/constants";

export const Register = (props) => {

    const loader = useLoader();
    const {product} = props;

    const {user, getAccessTokenSilently, isAuthenticated, loginWithRedirect, isLoading} = useAuth0();
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [label, setLabel] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [house, setHouse] = useState('');
    const [street, setStreet] = useState('');
    const [town, setTown] = useState('');
    const [county, setCounty] = useState('');
    const [postCode, setPostCode] = useState('');
    const [country, setCountry] = useState('');
    const [customer, setCustomer] = useState(null);
    const [priceId, setPriceId] = useState(null);
    const [productName, setProductName] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [authUser, setAuthUser] = useState(null);
    const [newUser, setNewUser] = useState(null);


    const [discountCode, setdiscountCode] = useState('');

    const PRODUCTS_NAMES = {
        "Lite": PRICES_IDS.LITE,
        "Plus": PRICES_IDS.PLUS,
        "Pro": PRICES_IDS.PRO,
    }

    useEffect(() => {
        const fetchPrices = async () => {
            const result = await fetch(`${API_ROUTE}/config`).then(r => r.json());
            if (result && result.prices) {
                result.prices.forEach(price => {
                    if (price.id === PRODUCTS_NAMES[product]) {
                        setProductName(price.product.name)
                        setPriceId(price.id);
                    }
                });
            }
        };
        fetchPrices();
    }, [])
    
    useEffect(() => {
        const urlEnd = product.toLowerCase();
        const path = `/register-${urlEnd}`;
        if (!isAuthenticated) {
            loginWithRedirect({
                screen_hint: "signup",
                appState: {returnTo: path}
            });
        }
    }, [loading, isAuthenticated, loginWithRedirect, customer]);
    useEffect(() => {
        if (!isLoading && user) {
            setEmail(user.email)
        }
    }, [isLoading, user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await getAccessTokenSilently();
        loader.showLoader();

        await fetch(`${API_ROUTE}/create-customer`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
                user: user,
                first_name: firstName,
                last_name: lastName,
                house_number: house,
                street_name: street,
                town: town,
                county: county,
                post_code: postCode,
                country: country,
                label_name: label,
                plan_name: productName
            }),
        }).then(r => r.json()).then((data) => {
            loader.hideLoader();
            if (typeof data === 'string') {
                setCustomer(undefined);
            } else {
                if (data && data.customer) {
                    setCustomer(data.customer);
                    setLoading(false);
                }
            }
        });
    };

    const pageHeader = (clientSecret) ? "Checkout" : "Enter user Info";
    const buttonText = customer ? "Please wait..." : "Register to continue";
    const errorMessage = (customer === undefined) ?
        <h2>You already have a subscription! Please Login or view your <a href='/my-account'>Account</a></h2> : "";

    const showCheckout = (s, c) => {
        const newsubscriptionId = {subscriptionId: s};
        const newClientSecret = {clientSecret: c};
        const newAuthUser = {auth0user: user};
        const tempUser = {user: customer};
        setSubscriptionId(newsubscriptionId);
        setClientSecret(newClientSecret);
        setAuthUser(newAuthUser);
        setNewUser(tempUser);
    }

    return (
        <div className='register-container'>
            <h1 className='register-container__header'>{pageHeader}</h1>
            {
                (subscriptionId && clientSecret && newUser) ?
                    
                    <LoadCheckout auth0User={authUser} subscriptionId={subscriptionId} clientSecret={clientSecret}
                                  user={newUser}
                                  planName={productName}/>
                    :
                    <form className='register-container__form' onSubmit={handleSubmit}>
                        {
                            (isAuthenticated && customer && priceId && productName) ? (
                                <div className='subscribe'>
                                    <h2>Almost There...</h2>
                                    <Subscribe customer={customer.id} priceId={priceId} planName={productName} discountCode={discountCode}
                                               showCheckout={showCheckout}/>
                                </div>
                            ) : (
                                <div className="subscription-detail">
                                    
                                    <div className='subscription-detail__personal'>
                                        <input className='subscription-detail__input' type="text" name="labelName" value={label}
                                            required
                                            placeholder='Label Name' onChange={(e) => setLabel(e.target.value)}
                                        />
                                        <input className='subscription-detail__input subscription-detail__inputsmall' type="text" name="firstName"
                                                   value={firstName}
                                                   required placeholder='First Name' onChange={(e) => setFirstName(e.target.value)}/>
                                        <input className='subscription-detail__input subscription-detail__inputsmall' type="text" name="lastName"
                                                   value={lastName}
                                                   required placeholder='Last Name' onChange={(e) => setLastName(e.target.value)}/>


                                        <input className='subscription-detail__input subscription-detail__inputsmall' type="text" name="phoneNumber" value={phoneNumber}
                                                required placeholder="Phone Number (include area code)"
                                               onChange={(e) => setPhoneNumber(e.target.value)}/>
                                    </div>


                                    <div className='subscription-detail__address'>

                                            <input className='subscription-detail__input subscription-detail__inputsmall' type="text" name="house" value={house}
                                                   required placeholder='House Number/Name' onChange={(e) => setHouse(e.target.value)}/>


                                            <input className='subscription-detail__input subscription-detail__inputmed' type="text" name="streetName"
                                                   value={street}
                                                   required placeholder='Street Name' onChange={(e) => setStreet(e.target.value)}/>
                                        
                                        
                                            <input className='subscription-detail__input subscription-detail__inputsmall' type="text" name="town" value={town}
                                                   required
                                                   placeholder='Town/City' onChange={(e) => setTown(e.target.value)}/>

                                            <input className='subscription-detail__input subscription-detail__inputsmall' type="text" name="county" value={county}
                                                   required placeholder='County/State' onChange={(e) => setCounty(e.target.value)}/>
                                        
                                        
                                            <input className='subscription-detail__input subscription-detail__inputsmall' type="text" name="post_code"
                                                   value={postCode}
                                                   required placeholder="Postcode/zip" onChange={(e) => setPostCode(e.target.value)}/>

                                            <input className='subscription-detail__input subscription-detail__inputsmall' type="text" name="country" value={country}
                                                   required placeholder="Country" onChange={(e) => setCountry(e.target.value)}/>

                                            <input className='subscription-detail__input subscription-detail__inputmed' type="text" name="discountCode"
                                                   value={discountCode}
                                                placeholder='Discount code' onChange={(e) => setdiscountCode(e.target.value)}/>      
                                        
                                    </div>
                                    <div>
                                        <button className="subscription-detail__btn" type="submit">
                                            {buttonText}
                                        </button>
                                    </div>

                                </div>
                            )
                        }
                    </form>
            }
            <div className='register-container__error'>{errorMessage}</div>
        </div>
    );
}
