import React, { useState, useEffect } from "react";
import TrackList from "../Tracklist/Tracklist";
import TrackEditor from "../TrackEditor/TrackEditor";
import TrackUploader from "../TrackUploader/TrackUploader";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoader } from "../LoaderProvider/LoaderProvider";
import {API_ROUTE} from "../../utilities/api";



const TracksView = ({ handleChange, data, setData, subGenres }) => {
    console.log("data in trackcsview", data);
    const loader = useLoader();
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [showList, setShowList] = useState(true);
    const [track, setTrack] = useState(null);
    const [tracks, setTracks] = useState(data.assets);
    const [audioToUpload, setAudioToUpload] = useState(null);
    const [popup, setPopup] = useState("popup-minimised");

    const createAsset = async () => {
        const token = await getAccessTokenSilently();
        let formData = new FormData();
        formData.append("asset_file", audioToUpload);
        let asset = JSON.stringify({
            product_id: data.id,
            asset_metadata: {
                type: "TRACK"
            }
        });
        formData.append("asset", asset);
        loader.showLoader();

        await fetch(`${API_ROUTE}/create-asset`, {

            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept': "multipart/form-data",
            },
            body: formData,
        }).then(r => r.json()
        ).then((result) => {
            loader.hideLoader();

            if (result) {
                setPopup("popup-minimised");
                const uploadedTrack = {...result.asset_metadata};
                //uploadedTrack['c_line_year'] = data.c_line_year;
                //uploadedTrack['c_line_text'] = data.c_line_text;
                uploadedTrack['p_line_year'] = data.p_line_year;
                uploadedTrack['p_line_text'] = data.p_line_text;
                setTrack(uploadedTrack);
                setShowList(false);
                const newTracks = [...tracks];
                newTracks.push(uploadedTrack);
                setTracks(newTracks);
                const changeData = {
                    target: {
                        name: 'assets',
                        value: newTracks
                    }
                }
                handleChange(changeData, false);
            }

        }).catch(() => {
            loader.hideLoader();

        })
    }

    const addTrack = async () => {
        setPopup("upload");
    }


    const getAssetInfo = async (assetId) => {
        const token = await getAccessTokenSilently();
        loader.showLoader();
        console.log("get-asset-info", assetId);

        await fetch(`${API_ROUTE}/get-asset-info/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                assetId: assetId
            }),
        }).then(r => r.json()
        ).then((data) => {
            console.log('inside getAssetInfo');
            loader.hideLoader();
            console.log("contributorsForAsset---->>:>>", data)

            setTrack(data.response);
            console.log("set track editTrack", track);
            setShowList(false);
        }).catch(() => {
            loader.hideLoader();
        })
    }
    const editTrack = (track, index, isNew) => {
        console.log("editasset", track);
        getAssetInfo(track.id);

    }
    const backToList = () => {
        setShowList(true);
    }
    const updateTrackInList = (trackData) => {
        const currentData = { ...data };
        const trackIndex = currentData.assets.findIndex(item => item.id === trackData.id);
        if (typeof trackIndex === 'number' && trackIndex >= 0) {
            currentData.assets[trackIndex] = trackData;
        }
        setData(currentData);
    }

    const handleTracksChange = (newTrack) => {
        const isExistTrack = tracks.find(item => newTrack.id === item.id);
        setTrack(newTrack);
        setShowList(false);
        if (!isExistTrack) {
            tracks.push(track);
            let newTracks = tracks;
            setTracks(tracks);
        }
    }

    useEffect(() => {
        if (!data.assets) {
            data.assets = [];
            setTracks(data.assets);
        }
    }, [data])

    return (
        <div className="tracks">
            {
                showList ? (
                    <TrackList handleChange={handleChange} editTrack={editTrack} addTrack={addTrack} data={data} />
                ) : (
                    <TrackEditor setTrack={setTrack} handleChange={handleTracksChange} updateTrackInList={updateTrackInList} track={track} data={data} backToList={backToList} subGenres={subGenres} />
                )
            }
            <TrackUploader setTrigger={setPopup} createAsset={createAsset} productData={data} popup={popup} setAudioToUpload={setAudioToUpload} />
        </div>
    )
}

export default TracksView;
