import axios from 'axios';

export const API_ROUTE = process.env.REACT_APP_API_DOMAIN || 'https://staging-api.3tonemusic.com';

const jsonHeaders = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
}

const formDataHeaders = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/x-www-form-urlencoded',
}
const secureApiJSON = axios.create({ baseURL: API_ROUTE, headers: jsonHeaders });
const secureApiFormatData = axios.create({ baseURL: API_ROUTE, headers: formDataHeaders });

secureApiJSON.interceptors.response.use(
    res => res.data,
    error => {
        if (error.response && error.response.data) {
            if (error.response.data.error) {
                return error.response.data;
            } else {
                return {
                    error: {
                        message: error.response.data.message
                    }
                };
            }
        }
    }
);

secureApiFormatData.interceptors.response.use(
    res => res.data,
    error => {
        if (error.response && error.response.data) {
            if (error.response.data.error) {
                return error.response.data;
            } else {
                return {
                    error: {
                        message: error.response.data.message
                    }
                };
            }
        }
    }
);

export {
    secureApiJSON,
    secureApiFormatData,
}

