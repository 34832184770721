import React from "react";
import "./UploadForm.scss";
import { languages, countryList, countryCodes2 } from "../../assets/mockData/international";
import { fugaGenres } from "../../assets/mockData/genres";
import Tooltip from "../Tooltip/Tooltip";
import SecondaryGenreSelect from "../SecondaryGenreSelect/SecondaryGenreSelect";
// import { TRUE } from "sass";

const UploadFormStep2 = (props) => {

    const {handleChange, data, subGenres} = props;

    return(
        <div className="upload-form--step2">
            <div className="upload-form--step2__half-container--left">
                <label className="upload-form__label">Primary Genre<span className="upload-form__span">*</span></label>
                <div className="upload-form__input">
                    <select value={data.genre.id/*data.genre.name*/} className="upload-form__input__select" required name="genre" onChange={handleChange}>
                        <option value="" disabled selected>Select...</option>
                        {fugaGenres && fugaGenres.map((e) => {
                            console.log("fugaGenres" + fugaGenres)
                            return (

                                <option key={e.id} value={e.id} >{e.name}</option>

                            );
                        })}
                    </select>
                </div>
                <label className="upload-form__label">Secondary Genre</label>

                <SecondaryGenreSelect name={'subgenre'} onChange={handleChange} values={subGenres}/>
                <label className="upload-form__label">Advisory<span className="upload-form__span">*</span><Tooltip text="Select 'Explicit' if there is and explicit language, only select 'Clean' if there is already a corresponding Explicit track"/></label>
                <div className="upload-form__input">
                    <select value={data.parental_advisory} className="upload-form__input__select" name="parental_advisory" required onChange={handleChange}>
                        <option value="" disabled selected>Select...</option>
                        <option value="false" >None</option>
                        <option value="true" >Explicit</option>

                    </select>
                </div>
                <label className="upload-form__label">Recording Location</label>
                <div className="upload-form__input">
                    <select value={data.recording_location} className="upload-form__input__select" name="recording_location" onChange={handleChange}>
                        <option value="" disabled selected>Select...</option>
                        {countryList && countryList.map((e) => {
                            return (

                                <option key={e} value={e} >{e}</option>

                            );
                        })}
                    </select>
                </div>
                <label className="upload-form__label">MetaData Language<span className="upload-form__span">*</span><Tooltip text="Language used for Product and Track titles"/></label>
                <div className="upload-form__input">
                    <select value={data.language} className="upload-form__input__select" required name="language" onChange={handleChange}>
                        <option value="" disabled selected>Select...</option>
                        {countryCodes2 && countryCodes2.map((e) => {
                            return (

                                <option key={e.id} value={e.id} >{e.name}</option>

                            );
                        })}
                    </select>
                </div>
                <label className="upload-form__label">Audio Language<span className="upload-form__span">*</span><Tooltip text="Language of the lyrics. Select '-No Linguistic Content' for instrumental tracks"/></label>
                <div className="upload-form__input">
                    <select value={data.audio_language} className="upload-form__input__select" required name="audio_language" onChange={handleChange}>
                        <option value="" disabled selected>Select...</option>
                        <option value="ZXX" >Instrumental</option>
                        {countryCodes2 && countryCodes2.map((e) => {
                            return (

                                <option key={e.id} value={e.id} >{e.name}</option>

                            );
                        })}
                    </select>
                </div>

                <label className="upload-form__label">(C) Info<span className="upload-form__span">*</span></label>
                <input value={data.c_line_text} type="text" name="c_line_text" className={'upload-form__input'} onChange={handleChange}></input>
                <label className="upload-form__label">(C) Year<span className="upload-form__span">*</span></label>
                <input value={data.c_line_year} type="number" name="c_line_year" className={'upload-form__input'} onChange={handleChange}></input>
            </div>
            <div className="upload-form--step2__half-container">
                <label className="upload-form__label">Release Date<span className="upload-form__span">*</span><Tooltip text="The product will go live to DSPs on this date"/></label>
                <input className="upload-form__input" value={data.consumer_release_date} required type="date" id="start" name="consumer_release_date" min="1900-01-01" max="" onChange={handleChange}></input>
                <label className="upload-form__label">Original Release Date<span className="upload-form__span">*</span><Tooltip text="Used to determine the order of an artist's catalogue, select the same date as the 'Release Date' for a new release."/></label>
                <input className="upload-form__input" value={data.original_release_date} required type="date" id="start" name="original_release_date" min="1900-01-01" max="" onChange={handleChange}></input>
                <label className="upload-form__label">Pre-Order Date<span className="upload-form__span">*</span><Tooltip text="Date the product will be available for pre-order on download stores."/><span class="make-it-smaller">Preorder date must be before Release date</span><span/></label>
                <input className="upload-form__input" value={data.preorder_date} type="date" id="start" name="preorder_date" min="1900-01-01" max="" onChange={handleChange}></input>
                <label className="upload-form__label">UPC/EAN<Tooltip text="Leave this field blank and a UPC will be auto-assigned."/></label>
                <input value={data.upc} type="text" className="upload-form__input" name="upc" onChange={handleChange}></input>
                <label className="upload-form__label">ISRC</label>
                <input value={data.isrc} type="text" className="upload-form__input" name="isrc" onChange={handleChange}></input>
                <label className="upload-form__label">Clip Start Time</label>
                <input value={data.clip_start_time} type="text" className="upload-form__input" name="clip_start_time" onChange={handleChange}></input>

                <label className="upload-form__label">(P) Info<span className="upload-form__span">*</span></label>
                <input value={data.p_line_text} type="text" name="p_line_text" className={'upload-form__input'} onChange={handleChange}></input>
                <label className="upload-form__label">(P) Year<span className="upload-form__span">*</span></label>
                <input value={data.p_line_year} type="number" name="p_line_year" className={'upload-form__input'} onChange={handleChange}></input>
            </div>
        </div>
    )
}

export default UploadFormStep2;
