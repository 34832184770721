import React from "react";

const UploadFormReview = (props) => {
    const {data} = props;
    console.log(data, 'ShingPong');

    return (
        <div className="upload-form--step5">
                <p>Your product is set up. You now need to edit track specific metadata to get the release ready for
                        delivery to the DSPs.</p>
                <br></br>
                <div className="upload-form--step5__label">Format: <p>{data.release_format_type}</p></div>
                <div className="upload-form--step5__label">Title: <p>{data.name.toString()}</p></div>
                <div className="upload-form--step5__label">Title Version: <p>{data.release_version.toString()}</p></div>
                <div className="upload-form--step5__label">Label: <p>{data.label.toString()}</p></div>
                <div className="upload-form--step5__label">Track: <p>{data.tracks.toString()}</p></div>
                <br></br>
                <div className="upload-form--step5__label">Primary Genre: <p>{data.genre.toString()}</p></div>
                <div className="upload-form--step5__label">Secondary Genre: <p>{data.subgenre.toString()}</p></div>
                <div className="upload-form--step5__label">Advisory: <p>{data.parental_advisory.toString()}</p></div>
                <div className="upload-form--step5__label">Recording
                        Location: <p>{data.recording_location.toString()}</p>
                </div>
                <div className="upload-form--step5__label">MetaData Language: <p>{data.language.toString()}</p></div>
                <div className="upload-form--step5__label">Audio Language: <p>{data.language.toString()}</p></div>
                <div className="upload-form--step5__label">Release Date: <p>{data.consumer_release_date.toString()}</p>
                </div>
                <div className="upload-form--step5__label">Original Release
                        Date: <p>{data.original_release_date.toString()}</p></div>
                <div className="upload-form--step5__label">Pre-Order Date: <p>{data.preorder_date.toString()}</p></div>
                <div className="upload-form--step5__label">UPC/EAN: <p>{data.upc.toString()}</p></div>
                <div className="upload-form--step5__label">IRSC: <p>{data.isrc.toString()}</p></div>
                <div className="upload-form--step5__label">Clip Start Time: <p>{data.clip_start_time}</p></div>
                <div className="upload-form--step5__label">(C) Info: <p>{data.c_line_text}</p></div>
                <div className="upload-form--step5__label">(C) Year: <p>{data.c_line_year}</p></div>
                <div className="upload-form--step5__label">(P) Info: <p>{data.p_line_text}</p></div>
                <div className="upload-form--step5__label">(P) Year: <p>{data.p_line_year}</p></div>
                <br></br>
                <div className="upload-form--step5__label">
                        Artists: {data.artists.map((e, index) => {
                        return (<p key={index}>{e.primary}: {e.name.toString()}</p>)
                })}
                </div>
                <div className="upload-form--step5__label">
                        Writers: {data.writers.map((e, index) => {
                        return (<p key={index}>{e.primary}: {e.name.toString()}</p>)
                })}
                </div>
                <div className="upload-form--step5__label">
                        Contributors: {data.contributors.map((e, index) => {
                        return (<p key={index}>{e.primary}: {e.name.toString()}</p>)
                })}
                </div>
                {
                    data.cover_image_string && (
                        <img className="upload-form--step5__img" src={data.cover_image_string} alt="3toneMusic"></img>
                    )
                }
        </div>
    )
}

export default UploadFormReview;

/*



*/
