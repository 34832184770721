import React from "react";
import "./Home.scss";
import ThreeDBackground from "../../components/3Dbackground/ThreeDBackgroundC";//R
import img1 from "../../assets/img/Asset20n.png";
import img2 from "../../assets/img/Asset21n.png";
import img3 from "../../assets/img/Asset22n.png";
import img4 from "../../assets/img/Asset23n.png";
import img5 from "../../assets/img/Asset25n.png";
import AnimatedCollage from "../../components/AnimatedCollage/AnimatedCollage";
//import ProductList from "../../components/ProductList/DummyProductList";
//import Checklist from "../../components/Checklist/Checklist";
//import ProductTapes from "../../components/ProductTapes/ProductTapes";
import ShaderBackground from "../../components/ShaderBackground/ShaderBackgroundFaded";//Starter";
import Prices from "../../components/Prices/Prices";
import ArtistTestimonial from "../../components/ArtistTesimonial/ArtistTestimonial";
import nxdia from "../../assets/img/nxdia.png";
import dspsDesktop from "../../assets/img/dsps-desktop.png";
import dspsMobile from "../../assets/img/dsps-mobile.png";
import troi from "../../assets/img/troi.png";
import GetPlaylisted from "../../components/GetPlaylisted/GetPlaylisted";
import { useAuth0 } from "@auth0/auth0-react";
import ServicesBlock from "../../components/ServicesBlock/ServicesBlock";
import WhatsIncluded from "../../components/WhatsIncluded/WhatsIncluded";


import nxdiaTitle from "../../assets/img/nxdia-title.png";
import troiTitle from "../../assets/img/troi-title.png";

const Home = () => {
  //const { user, isAuthenticated } = useAuth0();
  //console.log(isAuthenticated, user);

  return (
    <>
    <div className="homepage-container">
      <div className="homepage-container__top">
      <ThreeDBackground />
        <div className="homepage-container__top__title-container">
          <h1 className="homepage-container__top__title-container__title">RELEASE <br></br>UNLIMITED <br></br>MUSIC <br></br>EVERYWHERE</h1>
          <h4 className="homepage-container__top__title-container__subtitle">UNLIKE DISTROKID AND DITTO, WE ACTUALLY MEAN <br></br>UNLIMITED AND EVERYWHERE</h4>
        </div>
        <div className="homepage-container__top__collage-container">
          <AnimatedCollage />
        </div>
      </div>
      <div className="homepage-container__mid">
        <Prices />
      </div>
      <WhatsIncluded />
      <div className="dsps" id="dsps">
        <img className="dsps__desktop" src={dspsDesktop} />
        <img className="dsps__mobile" src={dspsMobile} />
        <span>+ Many more</span>
      </div>
      <div className="homepage-container__bottom">
        <GetPlaylisted />
        <div className="testimonial-container">
            <div className="testimonial">
              <img className="testimonial__img" src={nxdia} alt="3tonemusic"></img>
              <div className="testimonial__text">
                <img className="testimonial__text__title" src={nxdiaTitle} alt="3tonemusic" />
                  <p className="testimonial__text__quote">"It's great to get the oppurtunity to have my ideas heard and bounced between me and another person."</p>
              </div>
          </div>
          <div className="testimonial">
              <img className="testimonial__img" src={troi} alt="3tonemusic"></img>
              <div className="testimonial__text">
              <img className="testimonial__text__title" src={troiTitle} alt="3tonemusic" />
                  <p className="testimonial__text__quote">"I can tell you that they all deeply care about music. They put the artist first as much as they say they do. It's not a cute PR move - that aspect of their message is genuine."</p>
              </div>
          </div>
        </div>
        <ServicesBlock />
      </div>
    </div>
    </>
  );
};

export default Home;

/*
<Collage img1={img1} img2={img2} img3={img3} img4={img4} img5={img5} />

<div className="homepage-container">
      <div className="homepage-container__top">
      <ThreeDBackground />
        <div className="homepage-container__top__title-container">
          <h1 className="homepage-container__top__title-container__title">RELEASE <br></br>UNLIMITED <br></br>MUSIC <br></br>EVERYWHERE</h1>
          <h4 className="homepage-container__top__title-container__subtitle">UNLIKE DISTRO AND DITTO, WE ACTUALLY MEAN <br></br>UNLIMITED AND EVERYWHERE</h4>
        </div>
        <Collage img1={img1} img2={img2} img3={img3} img4={img4} img5={img5} />
        </div>
      <div className="homepage-container__mid">
        <ProductList />
      </div>
    </div>
*/
