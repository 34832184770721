const appActions = store => ({
    setArtistsFullList: (state, props) => (
        store.setState({ artistsFullList: props })
    ),
    setPeopleFullList: (state, props) => (
        store.setState({ peopleFullList: props })
    ),
    setPublishingHousesFullList: (state, props) => (
        store.setState({ publishingHousesFullList: props })
    ),
    setCurrentUser: (state, props) => (
        store.setState({ currentUser: props })
    )
});

export default appActions;
