import React, { useState } from "react";
import TrackInstantGrat from "../TrackInstantGrat/TrackInstantGrat";
import TrackLyrics from "../TrackLyrics/TrackLyrics";
import TrackMedia from "../TrackMedia/TrackMedia";
import { useAuth0 } from "@auth0/auth0-react";
import TrackMetadata from "../TrackMetadata/TrackMetadata";
import { ToastContainer, toast } from 'react-toastify';
import "./TrackEditor.scss";
import {API_ROUTE} from "../../utilities/api";
import {useLoader} from "../LoaderProvider/LoaderProvider";

const TrackEditor = ({subGenres, handleChange, setTrack, track, data, backToList, updateTrackInList}) => {
    const [menuClassName, setMenuClassName] = useState("track-editor__menu metadata");
    const [view, setView] = useState('metadata');
    const [label, setLabel] = useState(data.label.fuga_label_id);
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const loader = useLoader();
    const saveAsset = async () => { //

        loader.showLoader();
        const AssetId = track.id;
        const assetRequest = { ...track };
        function clean(request) {
            for (const propName in request) {
                if (request[propName] === null || request[propName] === undefined) {
                    delete request[propName];
                }
            }
            return request
        }
        if (assetRequest.genre.name != null) {
            assetRequest.genre = assetRequest.genre.id;
        }
        delete assetRequest.actions;
        delete assetRequest.audio;
        delete assetRequest.contributors;
        delete assetRequest.organization;
        delete assetRequest.original_encodings;
        delete assetRequest.video;
        delete assetRequest.video_hd;
        delete assetRequest.video_preview_image;
        delete assetRequest.work;

        let updateRequest = clean(assetRequest);


        await fetch(`${API_ROUTE}/update-asset`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                AssetId: AssetId,
                UpdateRequest: updateRequest
            }),
        }).then(r => r.json()
        ).then((data) => {
            if (data.is_request_successful) {
                getAssetInfo(track.id, data.response_message);

            } else {
                toast.error(data.response_message);
                loader.hideLoader();
            }
        }).catch(() => {
            loader.hideLoader();
        })
    }

    const getAssetInfo = async (assetId, successMessage) => {
        const token = await getAccessTokenSilently();

        console.log("get-asset-info", assetId);

        await fetch(`${API_ROUTE}/get-asset-info/`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                assetId: assetId
            }),
        }).then(r => r.json()
        ).then((data) => {
            setTrack(null);
            updateTrackInList(data.response);
            toast.success(successMessage);
            loader.hideLoader();
            backToList();
        }).catch(() => {
            loader.hideLoader();
        })
    }

    const handleTrackChange = (e) => {
        let newData = { ...track, [e.target.name]: e.target.value }
        handleChange(newData);
    }

    const handleDisplay = (display) => {
        setMenuClassName(`track-editor__menu ${display}`);
        setView(display);
    }

    return (

        <div className="track-editor">
            <div className="track-editor__header">
                <h4>{track.name}</h4>
                <h5>{track.display_artist}</h5>
                <button className="track-editor__header__button--save" onClick={() => saveAsset()}>Save Track</button>
                <button className="track-editor__header__button" onClick={() => backToList()}>Back to track list</button>
            </div>
            <div className={menuClassName}>
                <button className="track-editor__menu__button" onClick={() => handleDisplay("metadata")}>Track Metadata</button>
            </div>
            {
                view === 'metadata' && (

                    <TrackMetadata handleTrackChange={handleTrackChange} track={track} data={data} subGenres={subGenres} label={label}/>
                )
            }
            {
                view === 'lyrics' && (
                    <TrackLyrics handleTrackChange={handleTrackChange} track={track} />
                )
            }
            {
                view === 'lyrics' && (
                    <TrackInstantGrat handleTrackChange={handleTrackChange} track={track} />
                )
            }
            {
                view === 'media' && (
                    <TrackMedia />
                )
            }
        </div>
    )
}

export default TrackEditor;
