import React, {useEffect, useState} from "react";
import MetadataEditor from "../MetaDataEditor/MetadataEditor";
import placeHolderCover from "../../assets/img/3tone_gradient.png";
import TracksView from "../TracksView/TracksView"
import "./ReleaseEditor.scss"
import ReleaseTerritories from "../ReleaseTerritories/ReleaseTerritories";
import ReleaseDeliveries from "../ReleaseDeliveries/ReleaseDeliveries";
import ArtUploader from "../ArtUploader/ArtUploader";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoader } from "../LoaderProvider/LoaderProvider";

import { ToastContainer, toast } from 'react-toastify';
import {API_ROUTE} from "../../utilities/api";



const ReleaseEditor = (props) => {
    const loader = useLoader()
    const { getAccessTokenSilently } = useAuth0();

    const { data, newProduct, user, labels, subGenres, isHandlingSubmit, setIsHandlingSubmit, setShowSaveButton } = props;
    const [productData, setProductData] = useState(data);
    const [validationError, setValidationError] = useState(null);
    if (newProduct === true) {
        let newData = { ...productData }
        newData.Published = 'new';
    }

    const [content, setContent] = useState("Metadata");

    const [ImgToUpload, setImgToUpload] = useState(null);
    const [popup, setPopup] = useState("popup-minimised");

    const handleChange = (e, isDeleting) => {
        if (isDeleting === true) {
            const currentData = { ...productData };
            const currentAssets = [...currentData.assets];
            currentAssets.splice(e, 1);
            currentData['assets'] = currentAssets;
            setProductData(currentData);
        } else {
            let newData = { ...productData, [e.target.name]: e.target.value }
            setProductData(newData);
        }
    }

    const reformatGenre = (string) => {
        return  string.toUpperCase().replace(/ /g, "_").replace("/", "_").replace(/&/g, "_").replace(/'/g, "");
    }

    const productValidationRules = {
        requiredFields: [
            'cover_image',
            'name',
            'label',
            'display_artist',
            'consumer_release_date',
            'original_release_date',
            'genre',
            'release_format_type',
            'parental_advisory',
            'language',
            'c_line_text',
            'c_line_year',
            'p_line_text',
            'p_line_year'
        ]
    }

    const validateProduct = (product) => {
        let isValidProduct = true;
        const errors = {};
        productValidationRules.requiredFields.forEach(item => {
            if (!product[item] && typeof product[item] !== 'boolean') {
                isValidProduct = false;
                errors[item] = true;
            } else {
                errors[item] = false;
            }
        });
        setValidationError(errors)
        return isValidProduct;
    }

    const addAuthorToProduct = (newElement) => {
        let currentProduct = productData
        currentProduct.authors.push(newElement)
        setProductData(currentProduct)
    }

    const openTracks = () => {
        if (!validateProduct(productData)) {
            toast.error('Please save release before adding tracks')
            return false;
        }
        setContent("Tracks");
    }

    const handleSave = async (product) => {
        if (!validateProduct(productData)) {
            toast.error('Please fill required fields!')
            return false;
        }
        const token = await getAccessTokenSilently();
        if (product.Published === 'new' || product.Published === "incomplete" || product.state === undefined) {//if new product
            product.upc = "";
            let formData = new FormData();
            productData.artists = productData.artists.map(({ id, primary }) => ({ id: id, primary: primary }))
            let rest = {...productData};
            formData.append("id", data.id);
            formData.append("asset_file", productData.coverImg);
            rest.coverImg = null;
            function clean(rest) {
                for (const propName in rest) {
                    if (rest[propName] === null || rest[propName] === undefined || rest[propName] === "") {
                        delete rest[propName];
                    }
                }
                return rest
            }
            delete rest.cover_image_string;
            delete rest.territories;
            delete rest.assets;
            delete rest.state;
            delete rest.label;
            delete rest.acquisition;
            delete rest.product_type;
            delete rest.artwork;
            delete rest.organisation;
            delete rest.created_date;
            delete rest.modified_date;
            delete rest.required_fields;
            delete rest.tags;
            delete rest.custom_fields;
            delete rest.has_been_delivered;
            delete rest.hashed_key;
            delete rest.parental_advisory_next;
            delete rest.cover_image;
            delete rest.organization;
            delete rest.total_assets;
            rest = clean(rest);
            if (rest.genre.name != null) {
                rest.genre = rest.genre.id;
            }
            if (rest.subgenre.id != null){
                rest.subgenre = rest.subgenre.id;
            }
            formData.append("product", JSON.stringify({ rest }));
            loader.showLoader();

            await fetch(`${API_ROUTE}/create-product-two`, {

                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Accept': "multipart/form-data"

                },
                body: formData,

            }).then(r => r.json()
            ).then((data) => {
                loader.hideLoader();
                console.log('success');
                if (data.is_request_successful) {
                    console.log("artists after create", data.product_result)
                    console.log("artists after create", data.product_result.artists)
                    toast.success(data.response_message)
                    setProductData(data.product_result)
                } else {
                    toast.error(data.response_message)
                }
                console.log(data);
            }).catch((error) => {
                console.log('error');
                console.log(error);

                loader.hideLoader();
                toast.error(error)
            })
        }
    }

    const menuClass = `release-editor__editor__menu ${content}`;

    useEffect(() => {
        if (isHandlingSubmit) {
            console.log('saving')
            handleSave({ data });
            setIsHandlingSubmit(false)
        }
    }, [isHandlingSubmit]);
    useEffect(() => {
        if (productData && productData.state === "DELIVERED") {
            setShowSaveButton(false);
        } else {
            setShowSaveButton(true);
        }
    }, [productData]);

    return (
        <div className="release-editor">
            <div className="release-editor__product-summary">
                <img className="release-editor__product-summary__img" src={`data:image/jpeg;base64,${data.cover_image_string}`} alt={placeHolderCover} ></img>
                <div className="release-editor__product-summary__info">
                    <h3 className="release-editor__product-summary__info__header">{productData.name}</h3>
                    {/* <p className="release-editor__product-summary__info__header">{data.artists.map((e) => {return(<p>{e.name.toString()}</p>)})}</p> */}

                    <div className="release-editor__product-summary__info__info">Release Date: <p>{productData.original_release_date.toString()/*???*/}</p></div>
                    <div className="release-editor__product-summary__info__info">Label: <p>{productData.label.name}</p></div>
                    <div className="release-editor__product-summary__info__info">UPC/EAN: <p>{data.upc}</p></div>
                    <div className="release-editor__product-summary__info__info">Catalog Number: <p>{data.catalog_number}</p></div>
                    <div className="release-editor__product-summary__info__info">Genre: <p>{data.genre.name}</p></div>
                    <button className="release-editor__product-summary__info__btn" onClick={() => setPopup(true)}>Upload New Album Art</button>
                </div>
                <span className="release-editor__product-summary__light-container">
                    <p className="release-editor__product-summary__light-container__text">Status: </p><p className="release-editor__product-summary__light-container__text">{data.state}</p>
                </span>
            </div>
            <div className="release-editor__editor">
                <div className={menuClass}>
                    <button className="release-editor__editor__menu__button" onClick={() => { setContent("Metadata") }}>MetaData</button>
                    <button className="release-editor__editor__menu__button" onClick={openTracks}>Tracks</button>
                    <button className="release-editor__editor__menu__button" onClick={() => { setContent("Territories") }}>Territories</button>
                    <button className="release-editor__editor__menu__button" onClick={() => { setContent("Deliveries") }}>Deliveries</button>
                </div>
                <div className="release-editor__editor__form">
                    {
                        content === 'Metadata' && (
                            <MetadataEditor
                                handleChange={handleChange}
                                data={productData}
                                user={user}
                                labels={labels}
                                errors={validationError}
                                addAuthorToProduct={addAuthorToProduct}
                                subGenres={subGenres} />
                        )
                    }
                    {
                        content === 'Tracks' && (
                            <TracksView handleChange={handleChange} data={productData} setData={setProductData} subGenres={subGenres} />
                        )
                    }
                    {
                        content === 'Territories' && (
                            <ReleaseTerritories handleChange={handleChange} data={productData} />
                        )
                    }
                    {
                        content === 'Deliveries' && (
                            <ReleaseDeliveries />
                        )
                    }
                    {<ArtUploader setTrigger={setPopup} popup={popup} setImgToUpload={setImgToUpload} />}
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
        </div>
    )
}

export default ReleaseEditor;
