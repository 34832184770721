import React from "react";
import { NavLink } from "react-router-dom";
import "./AccountSupport.scss";

const AccountSupport = () => {

    return(
        <div className="account-support">
            <h1 className="account-support__header">Support</h1>
            <div className="account-support__halver">
                <iframe className="airtable-embed" src="https://airtable.com/embed/shrQzPB9mZ4HTNJZO?backgroundColor=gray" frameBorder="0"
                        width="100%" height="100%"></iframe>
            </div>
        </div>
    )
}

export default AccountSupport;
