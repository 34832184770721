import React, { useState, useEffect } from "react";
import {secureApiJSON} from "../../utilities/api";
import CreatableSelect from "react-select/creatable";

const SecondaryGenreSelect = ({onChange, name, selectedGenre, values, className}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const changeValue = (e) => {
        if (e) {
            setSelectedValue(e)
            onChange({
                target: {
                    name,
                    value: e.value
                }
            })
        } else {
            setSelectedValue({
                value: null,
                label: null
            });
            onChange({
                target: {
                    name,
                    value: ''
                }
            })
        }
    }

    const onCreate = async (e) => {
        setIsLoading(true);
        const result = await secureApiJSON.post('/create-subgenre', {name: e});
        setIsLoading(false);
        if (result) {
            const newValue = {
                label: result.name,
                value: result.id
            }
            const newOptions = [...options];
            newOptions.push(newValue);
            setOptions(newOptions);
            setTimeout(() => {
                setSelectedValue(newValue);
                onChange({
                    target: {
                        name,
                        value: newValue.value
                    }
                });
            })
        }
        setSelectedValue(null)
        onChange({
            target: {
                name,
                value: null
            }
        });
    }

    useEffect(() => {
        if (values) {
            const currentOptions = values.map(element => {
                return { value: element.id, label: element.name}
            })
            setOptions(currentOptions)
        }
    }, [values]);

    useEffect(() => {
        if (options.length > 0) {
            if (selectedGenre) {
                const currentGenre = options.find(item => item.value === selectedGenre);
                if (currentGenre) {
                    console.log(currentGenre)
                    setSelectedValue(currentGenre)
                }
            }
        }
    }, [selectedGenre, options]);

    return (
        <CreatableSelect isClearable
                         isLoading={isLoading}
                         isSearchable={true}
                         styles={{
                             valueContainer: (baseStyles, state) => ({
                                 ...baseStyles,
                                 height: '31px',
                                 margin: 0,
                                 padding: '0 8px',
                                 lineHeight: '31px',
                             }),
                             control: (baseStyles, state) => ({
                                 ...baseStyles,
                                 height: '31px',
                                 minHeight: '31px',
                                 borderRadius: 0,
                                 borderColor: 'black',
                                 outline: 'none',
                                 boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
                                 marginBottom: '0.5rem',
                                 padding: '0 5px',
                                 border: '1px solid #898989',
                                 backgroundColor: 'white',
                                 fontSize: '13px',
                                 boxSizing: 'border-box',
                                 display: 'flex',
                                 alignItems: 'flex-start',
                                 lineHeight: '31px',
                                 justifyContent: 'center'
                             }),
                             input: (baseStyles) => ({
                                 ...baseStyles,
                                 height: '25px',
                                 margin: 0,
                                 padding: '0 8px',
                                 lineHeight: '25px',
                             }),
                             indicatorsContainer: (baseStyles) => ({
                                 ...baseStyles,
                                 height: '31px',
                                 margin: 0,
                                 lineHeight: '31px',
                             })
                         }}
                         onChange={changeValue}
                         onCreateOption={onCreate}
                         options={options}
                         value={selectedValue} />
    )
}

export default SecondaryGenreSelect;
